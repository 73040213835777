import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { useFormik } from "formik";
import {  string } from "yup";
import * as Yup from "yup";
import { exportAnalytics, getAllPOS, getPaginationPOS, scheduledReportDelivery } from "../../redux/actions/analytics/analyticsActions"
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Divider, IconButton, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ExporterModal from 'components/Datepicker/Exportermodal';
import AnalyticsModal from 'components/Modal/AnalyticsModal';
import {AiFillCloseSquare} from 'react-icons/ai';
import { ReactMultiEmail } from 'react-multi-email';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import styles from "./styles"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Spinner } from "reactstrap"
import toast, { Toaster } from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import Radio from '@mui/material/Radio';
// import Switch from '@mui/material/Switch';
import { RadioGroup } from '@mui/material';
import { CustomTextField } from 'elements';
import { api_settings } from "../../redux/services/api";


const ExporterButton = styled('div')(({ theme }) => ({
    background: '#c8102e', 
    padding: 10, 
    borderRadius: 5, 
    flex: 'none', 
    fontFamily: 'Roboto', 
    cursor: 'pointer', 
    color: '#fff',
    fontSize: 12,
    float: 'right',
    marginBottom: 13,
    "&:hover":{
      background: "#eb314f",
      boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"
  },
  })) 

  const CustomTextFieldWrappper = styled('div')(({ theme }) => ({
    '& label': {
      fontSize: '1rem !important',
      fontFamily: 'Roboto !important',
      color: '#36333D !important',
    },
    '& input': {
      fontSize: '13px !important',
      padding: "0px 15px 0px 15px !important",
      height: "38px !important"
    },
    '& .MuiFormControl-root': {
       minHeight: "57px !important"
    }
  }))
  const useStyles = makeStyles(styles)
  
export default function AnalyticsExporter({code}) {
  const {t} = useTranslation('common');
  const classes = useStyles() 
    const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { loading,error,analytics,createError, createSuccess, exportLoading, posLoading, posSuccess, ccBucketAnalyticsScheduledReportDeliverySuccess, ccBucketAnalyticsScheduledReportDeliveryLoading,ccBucketAnalyticsScheduledReportDeliveryError } = useSelector((state) => state.analytics.analytics);

  const [analyticslist, setAnalyticslist] = useState([]);
  const [lastItem, setLastItem] = useState(null);
  const [posList, setPosList] = useState([]);
  const [posSearch, setPosSearch] = useState("");
  const [checkedPosList, setCheckedPosList] = useState([]);
  const [posNextPage, setPosNextPage] = useState("");
  const [posSelectAll, setPosSelectAll] = useState(false);
  const [posPage, setPosPage] = useState(1)
  const [scheduleModal, setScheduleModal] = useState(false)
  const [dateValue, setDateValue] = useState()
  const [emails, setEmails] = React.useState([]);
  const [focused, setFocused] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const { impersonateUserInfo } = useSelector((state) => state.impersonate.impersonate);
    
    const formik = useFormik({
        initialValues: {
        startdate: "",
        enddate: "",
        posname: "",   
        date_type_analytics: "lastyear",
        poslist: [],
        reporttype: "sales_volume",
        reportemail: "" ,
        litreOrOrder: "order",
        date_type: "monthly"   
      },
      validationSchema: Yup.object({
        startdate: string().when("date_type_analytics", {is: "custom", then: string().required(t('analyticsExporter.StartDateisrequired'))}),
        enddate: string().when("date_type_analytics", {is: "custom", then: string().required(t('analyticsExporter.Enddateisrequired'))}),                                                                
      }),
      onSubmit : (values, formikHelpers)=> { 
        values.analyticsCode = lastItem;    
        if(posSearch.length > 0){
          dispatch(exportAnalytics(values, false)) 
        }
        else{
          dispatch(exportAnalytics(values, posSelectAll)) 
        }
        
     }
    });

  const [reportData, setReportData] = React.useState(["sales_volume", "top_sub_product", "top_product", "top_base", "top_ten_colors", "colorant_consumption"]);
  const [topSubProduct, setTopSubProduct] = React.useState(true);
  const [topProduct, setTopProduct] = React.useState(true);
  const [topTenColors, setTopTenColors] = React.useState(true);
  const [colorantConsumption, setColorantConsumption] = React.useState(true);
  const [topBase, setTopBase] = React.useState(true);
  const [salesVolume, setSalesVolume] = React.useState(true);
  const [annualSalesTrend, setAnnualSalesTrend] = React.useState(true);
  const [checkBoxError,setCheckboxError] = useState();
  const [emailError,setEmailError] = useState();

    

    const handleClose = () =>{
      formik.resetForm();
      setOpen(false);
    }
  

  const handlePOSSearch = () => {
    setPosNextPage("")
    setPosList([])
    setCheckedPosList([])
    getPosList(lastItem)
  }

  useEffect(() => {  
    if(posSuccess?.data){
      setPosNextPage(posSuccess?.next)
      if(posSuccess.data.length > 0){
        let prev_posList = posList
        posSuccess.data.map((item,index)=>{ 
          item.checked = false
          prev_posList.push(item)
        })
        setPosList(prev_posList)
        setCheckedPosList(prev_posList)
      }else{
        setPosList([])
        setCheckedPosList([])
      }
      if(posPage === 1){
        formik.setFieldValue('poslist', [])
        formik.setFieldValue('posname', "")
      }
    }      
  },[posSuccess]);

 
  useEffect(() => {    
    if(ccBucketAnalyticsScheduledReportDeliverySuccess?.status.length > 0){
      setScheduleModal(false)
      formik.resetForm()
      setEmails([])
      toast.success(t('analyticsExporter.Reportsentsuccessfully'), {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_SCHEDULED_REPORT_DELIVERY_SUCCESS",
        payload: null
      });
    }
    if(ccBucketAnalyticsScheduledReportDeliveryError){
      toast.success(t('errorBlock.somethingWentWrong'), {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_SCHEDULED_REPORT_DELIVERY_FAILED",
        payload: null
      });
    }
  },[ccBucketAnalyticsScheduledReportDeliverySuccess, ccBucketAnalyticsScheduledReportDeliveryError])

  const onChangeCheck = (item, key) => () => {
    let formik_poslist = formik.values.poslist
    let already_in = false
    formik_poslist.map((itemv,keyi)=>{ 
      if(itemv===item.pos_name){
        already_in = true;
        formik_poslist.splice(keyi, 1);
        setPosSelectAll(false)
      }
    })
    if(item.checked === true){
      formik.setFieldValue('poslist', formik_poslist)
      formik.setFieldValue('posname', formik_poslist.join(','))
      var input = [...posList]
      input[key].checked = false
      setPosList(input)
    }
    else{
      formik_poslist.push(item.pos_name)
      formik.setFieldValue('poslist', formik_poslist)
      formik.setFieldValue('posname', formik_poslist.join(','))
      var input = [...posList]
      input[key].checked = true
      const allChecked = input.every(item => item.checked);
      setPosSelectAll(allChecked)
      setPosList(input)
    }
  }

  const checkSelectAll = () => {
    let formik_poslist = formik.values.poslist
    setPosSelectAll(!posSelectAll)
    let prev_posList = [...posList]
    prev_posList.map((item, index) => {
      if(posSelectAll){
        formik.setFieldValue('poslist', [])
        formik.setFieldValue('posname', "")
        item.checked = false
      }
      else{
        formik_poslist.push(item.pos_name)
        formik.setFieldValue('poslist', formik_poslist)
        formik.setFieldValue('posname', formik_poslist.join(','))
        item.checked = true
       }
    })
    setPosList(prev_posList)
    setCheckedPosList(prev_posList)
  }

  const scrollBottom = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if(bottom){
      if(posSelectAll){}else{
        setPosPage(posPage+1)
        if(posNextPage !== ""){
          if(posNextPage === "" || posNextPage === null || posNextPage === undefined){

          }else{
            dispatch(getPaginationPOS({page:posNextPage}))   
          }          
        }else{
          getPosList(lastItem)
        }   
      }     
    }      
  }

  const loadMore = () => {
    if(posSelectAll){}else{
      setPosPage(posPage+1)
      if(posNextPage !== ""){
        if(posNextPage === "" || posNextPage === null || posNextPage === undefined){

        }else{
          dispatch(getPaginationPOS({page:posNextPage}))   
        }          
      }else{
        getPosList(lastItem)
      }   
    }       
}

const exporterButtonClick = () => {
    if(posSelectAll){
        setPosSelectAll(!posSelectAll)
      }
      setPosNextPage("")
      formik.setFieldValue('poslist', [])
      formik.setFieldValue('posname', "")   
      setLastItem(code)
      setPosList([])     
      setCheckedPosList([])
      setOpen(true)      
      getPosList(code)
  }

  const getPosList = (itemcode) => {    
    let payload = {
      analyticsCode: itemcode,
      search: formik.values.posname
    }  
    dispatch(getAllPOS(payload))      
}

useEffect(() => { 
  if(createSuccess?.status === "success"){
    // setOpen(false)
    // var base64replace1 = createSuccess.data.replace("b'", "");
    // var base64replace2 = base64replace1.replace("'", "");

    // var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    // var blob1 = b64toBlob(base64replace2, contentType);
    // var blobUrl1 = URL.createObjectURL(blob1);
    // dispatch({
    //   type: "DOWNLOAD_RESET",
    //   payload: null
    // })
    // var a = document.createElement("a");
    //   document.body.appendChild(a);
    //   a.style = "display: none";
    //   a.href = blobUrl1;
    //   a.download = "xdata-history-export-" + lastItem + "-" + formik.values.startdate + " 00_00_00-" + formik.values.enddate + " 23_59_59";
    //   a.click();
    // console.log("api_settings.url_base + downloadLogsSuccess.data.url", api_settings.url_base + createSuccess.data.url)
    const downloadLink = document.createElement('a');
    downloadLink.href = api_settings.url_base + createSuccess.data.url;
    // downloadLink.download = 'example.xlsx';

    // Append the link to the body and trigger a click
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Cleanup: Remove the link from the DOM
    document.body.removeChild(downloadLink);
    dispatch({
      type: "DOWNLOAD_RESET",
      payload: null
    });
  }   
  if(createError){
    dispatch({
      type: "REQUEST_CREATE_ANALYTICS_FAILED",
      payload: null
    })
    toast.error(t(createError?.data ? createError?.data?.message : 'errorBlock.somethingWentWrong'), {
      duration: 8000,
      position: 'top-right',
      style: {fontSize: 20, fontFamily: 'Roboto'}
    });
  }
},[createSuccess, createError])

const b64toBlob =(b64Data, contentType, sliceSize) =>  {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  
  var byteCharacters = atob(b64Data);
  var byteArrays = [];
  
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
  
    var byteArray = new Uint8Array(byteNumbers);
    
    byteArrays.push(byteArray);
  }
  
  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const scheduledBtnClick = () => {
  
  setScheduleModal(true)
}



const onChangeCheckReports = (type) => () => {
  formik.setFieldValue('reporttype', type)
}

const posSearchOnChange = (event) => {
    setPosSearch(event.target.value)
    setPosSelectAll(false)
    const keyword = event.target.value;
    if (keyword !== '') {
    const results = posSuccess.data?.filter(item =>
      Object.keys(item).some(k => {
        if(typeof item[k] !== "number" && typeof item[k] !== "boolean"){
          return item[k]?.toLowerCase().includes(keyword.toLowerCase())
        }
      }));
      setPosList(results)
    } else {
      setPosList(posSuccess.data)
    }
}

// const handleSchedule = () => {  
//   var withoutYear = [
//     "sales_volume", "top_sub_product", "top_product", "top_base", "top_ten_colors", "colorant_consumption"
//   ]
//   var withYear  = [ "sales_volume", "annual_sales_trend", "top_sub_product", "top_product", "top_base", "top_ten_colors", "colorant_consumption"
//   ]
//   let payload = {
//     report_choices: reportData,
//     start_date: formik.values.startdate,
//     end_date: formik.values.enddate,
//     email: formik.values.reportemail[0],
//     bcc_email: formik.values.reportemail[0],
//     bucket_code: code,
//     type: formik.values.litreOrOrder,
//     date_type : formik.values.date_type,
//     identity_name : formik.values.reportemail[0]
//   } 
//   if(reportData.length === 0 && formik.values.reportemail.length === 0){   
//     setCheckboxError(t('analyticsExporter.reportsRequired'))
//     setEmailError(t("signIn.Pleaseenteremail"))
//   }
//   else if(reportData.length === 0) {
//     setCheckboxError(t('analyticsExporter.reportsRequired'))
//     setEmailError("")
//   }
//   else if(formik.values.reportemail.length === 0){
//     setEmailError(t("signIn.Pleaseenteremail"))
//     setCheckboxError("")
//   }
//   else{
//     setEmailError("")
//     setCheckboxError("")
//     dispatch(scheduledReportDelivery(payload)) 
//   }
// }
 
const scheduledFormik = useFormik({
  initialValues: {
    reportemail: "",  
    startdate: "",
    enddate: "",
    date_type: "monthly",
    litreOrOrder: "order",
  },
  validationSchema: Yup.object({
    reportemail: string().required(t('signIn.Pleaseenteremail')).email(t('signIn.Invalidemail'))                                                              
  }),
  onSubmit : (values, formikHelpers)=> { 
    let payload = {
      report_choices: reportData,
      start_date: values.startdate,
      end_date: values.enddate,
      email: values.reportemail,
      bcc_email: values.reportemail,
      bucket_code: code,
      type: values.litreOrOrder,
      date_type : values.date_type,
      identity_name : values.reportemail
    } 
    if(reportData.length === 0){   
      setCheckboxError(t('analyticsExporter.reportsRequired'))
    }
    else{
      setCheckboxError("")
      dispatch(scheduledReportDelivery(payload)) 
    }
  }
});

const scheduleModalClose = () => {
  scheduledFormik.resetForm()
  setEmails([])
  setReportData([])
  setEmailError("")
  setCheckboxError("")
  setScheduleModal(false)
  setTopSubProduct(false)
  setTopProduct(false)
  setTopTenColors(false)
  setColorantConsumption(false)
  setTopBase(false)
  setSalesVolume(false)
  setAnnualSalesTrend(false)
}

useEffect(() => {  
  if(formik.values.reportemail.length > 0){
    setEmailError("")
  }
  if(reportData.length > 0){
    setCheckboxError("")
  }
   
},[formik.values.reportemail, reportData]);

const checkBoxChange = (event, eventItem, setValue) => {
  const reportArray = [...reportData]
  if(event === true){
    reportArray.push(eventItem)
    setReportData(reportArray)
    setValue(true)
  }
  else{
    var array = [...reportData]
    array.forEach((item,key)=>{ 
      if(item===eventItem){
        
        array.splice(key, 1);
      }
    })
    setValue(false)
    setReportData(array)
  }

}

useEffect(() => {  
  if(scheduledFormik.values.date_type === "halfyearly" || scheduledFormik.values.date_type === "monthly"){
    setReportData(["sales_volume", "top_sub_product", "top_product", "top_base", "top_ten_colors", "colorant_consumption"])
    setAnnualSalesTrend(false)
  }else{
    setReportData(["annual_sales_trend", "sales_volume", "top_sub_product", "top_product", "top_base", "top_ten_colors", "colorant_consumption"])
    setAnnualSalesTrend(true)
  }
  setCheckboxError("")
  setTopSubProduct(true)
  setTopProduct(true)
  setTopTenColors(true)
  setColorantConsumption(true)
  setTopBase(true)
  setSalesVolume(true)
  
},[scheduledFormik.values.date_type]);

const scheduleContent = (
  <>                        
      <div className={classes.head}>                 
          <div className={classes.text} >{t('analyticsExporter.ScheduledReportDelivery')}</div>                               
          <div className={classes.button}>
              <IconButton onClick={scheduleModalClose}>
                  <AiFillCloseSquare color='#C8102E' size="30px"/>
              </IconButton>
          </div>                
      </div>
      <Divider />
      <div style={{marginTop: 20}} className="email-style">
      <form onSubmit={scheduledFormik.handleSubmit}>
          <InputLabel className={classes.inputLabel} >{t('analyticsExporter.datetype')}</InputLabel>
          <RadioGroup name='date_type' defaultValue={"monthly"} style={{display: 'inline-block'}}>
              <FormControlLabel onChange={scheduledFormik.handleChange}  name="date_type" value="monthly"  control={<Radio  style ={{color: "#C8102E"}} />} 
                  label={t('analyticsExporter.month')}/>
              <FormControlLabel onChange={scheduledFormik.handleChange} style={{marginTop: 3}}  name="date_type" value="halfyearly"  control={<Radio  style ={{color: "#C8102E"}} />} 
              label={t('analyticsExporter.halfyear')}/>
              <FormControlLabel onChange={scheduledFormik.handleChange} style={{marginTop: 3}}  name="date_type" value="yearly"  control={<Radio  style ={{color: "#C8102E"}} />} 
              label={t('analyticsExporter.year')}/>
            </RadioGroup>
          {/* <ReactMultiEmail 
                  style={{marginTop: 10}}
                  name="reportemail"
                  emails={emails}
                  onChange={(_emails) => {
                    formik.setFieldValue('reportemail', _emails);
                    setEmails(_emails);
                  }}
                  autoFocus={true}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        <div style={{display: 'flex'}}>
                        <div data-tag-item>{email}</div>
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                          ×
                        </span>
                        </div>
                      </div>
                    );
                  }}
                /> */}
                <CustomTextFieldWrappper>
                  <CustomTextField
                      name="reportemail"
                      label={t('analyticsExporter.EmailId')}
                      // placeholder={t('analyticsExporter.EmailId')}
                      type="email"
                      onChange={scheduledFormik.handleChange}
                      onBlur={scheduledFormik.handleBlur}
                      value={scheduledFormik.values.reportemail}
                      error={scheduledFormik.touched.reportemail && Boolean(scheduledFormik.errors.reportemail)}
                      helperText={scheduledFormik.touched.reportemail && scheduledFormik.errors.reportemail} 
                      labelColor="#000"
                      labelTextSize={16}
                      inputbackgroundcolor="#E8E8E8"
                      inputtextcolor="#000"
                      placeholderColor="#A5A5A5"
                      helpertextcolor="#C8102E"  
                      height={56}            
                  />
                </CustomTextFieldWrappper>
                {/* <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 12, marginTop: 10}}>{emailError !== "" ? emailError : ""}</p>  */}
                {/* <div style={{marginTop: 20}}>
                 <span className={classes.chartRightChild} style={{flex: 'none'}}>Liter</span>
                  <span className={classes.chartRightChild}>
                    <Switch checked={formik.values.litreOrOrder === "order"? true : false} onChange={(event) => switchOnChange(event.target.checked)}  defaultChecked size="small" color="error"/>
                  </span>
                  <span className={classes.chartRightChild}  style={{flex: 'none'}}>Order</span>
              </div> */}
              <div style={{marginTop:4, fontSize: 14, fontFamily: 'Roboto'}}>{scheduledFormik.values.date_type === "monthly" ? 
              t('analyticsExporter.monthly'): scheduledFormik.values.date_type === "halfyearly" ? t('analyticsExporter.halfyearly') : t('analyticsExporter.yearly')}</div>
                <InputLabel className={classes.inputLabel} style={{marginTop: 15}}>{t('analyticsExporter.Reports')}</InputLabel>
                
                <div>
                  <FormControlLabel  name="reporttype" value="top_sub_product"  control={<Checkbox checked={topSubProduct}    style ={{color: "#C8102E"}}  onChange={(e) => checkBoxChange(e.target.checked, "top_sub_product", setTopSubProduct)} />} 
                    label={t('analyticsExporter.TopTenSubproducts')}/> 
                  <FormControlLabel name="reporttype" value="top_product"  control={<Checkbox  checked={topProduct}  style ={{color: "#C8102E"}} onChange={(e) => checkBoxChange(e.target.checked, "top_product", setTopProduct)} />} 
                    label={t('analyticsExporter.TopTenProducts')}/> 
                </div>
                <div>
                  <FormControlLabel name="reporttype" value="top_ten_colors"  control={<Checkbox checked={topTenColors} style ={{color: "#C8102E"}} onChange={(e) => checkBoxChange(e.target.checked, "top_ten_colors", setTopTenColors)} />} 
                    label={t('analyticsExporter.TopTenColors')}/> 
                  <FormControlLabel name="reporttype" value="colorant_consumption"  control={<Checkbox checked={colorantConsumption} style ={{color: "#C8102E"}} onChange={(e) => checkBoxChange(e.target.checked, "colorant_consumption", setColorantConsumption)} />} 
                    label={t('analyticsExporter.ColorantConsumption')}/> 
                </div>
                <div>
                  <FormControlLabel name="reporttype" value="top_base"  control={<Checkbox checked={topBase}  style ={{color: "#C8102E"}} onChange={(e) => checkBoxChange(e.target.checked, "top_base", setTopBase)} />} 
                    label={t('analyticsExporter.TopTenBases')}/>  
                    <FormControlLabel  name="reporttype" value="sales_volume"  control={<Checkbox checked={salesVolume} style ={{color: "#C8102E"}} onChange={(e) => checkBoxChange(e.target.checked, "sales_volume", setSalesVolume)} />} 
                    label={t('analyticsExporter.SalesVolume')}/>
                </div>
                <div>
                  {
                    scheduledFormik.values.date_type === "yearly" ? 
                    <FormControlLabel  name="reporttype" value="sales_trend"  control={<Checkbox  checked={annualSalesTrend}  style ={{color: "#C8102E"}} onChange={(e) => checkBoxChange(e.target.checked, "annual_sales_trend", setAnnualSalesTrend)} />} 
                    label={t('analyticsExporter.AnnualSalesTrend')}/> : 
                    null
                  }
                </div>
                <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 12}}>{checkBoxError !== "" ? checkBoxError : ""}</p> 
            <div className={classes.buttons} >                   
                <Button 
                className='appBtnCancel'                  
                    size="small"
                    onClick={scheduleModalClose}
                >
                    {t('analyticsExporter.Cancel')}
                </Button>                                          
                <div className={classes.sub}>
                    <Button
                    className='appBtn'                   
                    type="submit"
                        size="meduim"
                        
                    >
                      {ccBucketAnalyticsScheduledReportDeliveryLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('analyticsExporter.Schedule')}
                    </Button>
                </div>                                  
            </div>  
            </form>   
            </div>     
  </>
)

const { user } = useSelector((state) => state.auth.login);
if(impersonateUserInfo?.status === "success"){
  let impersonateUserInfoData = impersonateUserInfo?.data
  if(impersonateUserInfoData.is_workspace_graph === false){
      return (
        <>
        <Toaster />
        <ExporterButton onClick={() => exporterButtonClick()} style={{marginTop: -45}}>
            {t('analyticsExporter.AnalyticsExporter')}
          </ExporterButton>
        
          <AnalyticsModal 
                handleclose={handleClose} 
                open={open} 
                scrollBottom={scrollBottom}
                content={<ExporterModal onChange={(value) => {
                                let startdate = value.$d
                                let yyyy = startdate.getFullYear();
                                let mm = startdate.getMonth() + 1;
                                let dd = startdate.getDate();                            
                                if (dd < 10) dd = '0' + dd;
                                if (mm < 10) mm = '0' + mm;      
                                let formattedStartdate = yyyy + '-' + mm + '-' + dd;
                                formik.setFieldValue('startdate', formattedStartdate);
                            }}
                            error = {formik.touched.startdate && Boolean(formik.errors.startdate)}
                            errorenddate = {formik.touched.enddate && Boolean(formik.errors.enddate)}
                            
                            helperText = {formik.touched.startdate && formik.errors.startdate}
                            helperTextenddate = {formik.touched.enddate && formik.errors.enddate}
                            onChange1={(value) => {
                              let enddate = value.$d
                              let yyyy = enddate.getFullYear();
                              let mm = enddate.getMonth() + 1;
                              let dd = enddate.getDate();                            
                              if (dd < 10) dd = '0' + dd;
                              if (mm < 10) mm = '0' + mm;                            
                              let formattedEnddate = yyyy + '-' + mm + '-' + dd;
                              formik.setFieldValue('enddate', formattedEnddate);
                            }}
                            onChange2={posSearchOnChange}  
                            onChangeCheck={onChangeCheck}                
                            value={formik.values.startdate} 
                            value1={formik.values.enddate}
                            value2={formik.values.posname}
                            date_type_analytics={formik.values.date_type_analytics}
                            onSubmit={formik.handleSubmit}
                            checkSelectAll={checkSelectAll}
                            click={handleClose}
                            posList={posList}
                            posSelectAll={posSelectAll}
                            posLoading={posLoading}
                            handlePOSSearch={handlePOSSearch}
                            posNextPageUrl={posNextPage}
                            loadMore={loadMore}
                            checkedPosList={checkedPosList}
                            posSearch={posSearch}
                            formik={formik}
                            
                        />}
            />
            <AnalyticsModal 
                handleclose={scheduleModalClose} 
                open={scheduleModal} 
                scrollBottom={scrollBottom}
                content={scheduleContent}
            />
                          
        </>
      )
    }
  if(impersonateUserInfoData.is_workspace_graph === true){
      return (
        <>
        <Toaster />
        <ExporterButton onClick={() => exporterButtonClick()} style={{marginTop: -45}}>
            {t('analyticsExporter.AnalyticsExporter')}
          </ExporterButton>
      
        <ExporterButton onClick={() => scheduledBtnClick()} style={{marginTop: -45, marginRight: 20}}>
            {t('analyticsExporter.ScheduledReportDelivery')}
          </ExporterButton>
          <AnalyticsModal 
                handleclose={handleClose} 
                open={open} 
                scrollBottom={scrollBottom}
                content={<ExporterModal onChange={(value) => {
                                let startdate = value.$d
                                let yyyy = startdate.getFullYear();
                                let mm = startdate.getMonth() + 1;
                                let dd = startdate.getDate();                            
                                if (dd < 10) dd = '0' + dd;
                                if (mm < 10) mm = '0' + mm;      
                                let formattedStartdate = yyyy + '-' + mm + '-' + dd;
                                formik.setFieldValue('startdate', formattedStartdate);
                            }}
                            error = {formik.touched.startdate && Boolean(formik.errors.startdate)}
                            errorenddate = {formik.touched.enddate && Boolean(formik.errors.enddate)}
                            
                            helperText = {formik.touched.startdate && formik.errors.startdate}
                            helperTextenddate = {formik.touched.enddate && formik.errors.enddate}
                            onChange1={(value) => {
                              let enddate = value.$d
                              let yyyy = enddate.getFullYear();
                              let mm = enddate.getMonth() + 1;
                              let dd = enddate.getDate();                            
                              if (dd < 10) dd = '0' + dd;
                              if (mm < 10) mm = '0' + mm;                            
                              let formattedEnddate = yyyy + '-' + mm + '-' + dd;
                              formik.setFieldValue('enddate', formattedEnddate);
                            }}
                            onChange2={posSearchOnChange}  
                            onChangeCheck={onChangeCheck}                
                            value={formik.values.startdate} 
                            value1={formik.values.enddate}
                            value2={formik.values.posname}
                            date_type_analytics={formik.values.date_type_analytics}
                            onSubmit={formik.handleSubmit}
                            checkSelectAll={checkSelectAll}
                            click={handleClose}
                            posList={posList}
                            posSelectAll={posSelectAll}
                            posLoading={posLoading}
                            handlePOSSearch={handlePOSSearch}
                            posNextPageUrl={posNextPage}
                            loadMore={loadMore}
                            checkedPosList={checkedPosList}
                            posSearch={posSearch}
                            formik={formik}
                            
                        />}
            />
            <AnalyticsModal 
                handleclose={scheduleModalClose} 
                open={scheduleModal} 
                scrollBottom={scrollBottom}
                content={scheduleContent}
            />
                          
        </>
    )}
    }
else{
    if(user.is_workspace_graph === false){
    return (
      <>
      <Toaster />
      <ExporterButton onClick={() => exporterButtonClick()} style={{marginTop: -45}}>
          {t('analyticsExporter.AnalyticsExporter')}
        </ExporterButton>
      
        <AnalyticsModal 
              handleclose={handleClose} 
              open={open} 
              scrollBottom={scrollBottom}
              content={<ExporterModal onChange={(value) => {
                              let startdate = value.$d
                              let yyyy = startdate.getFullYear();
                              let mm = startdate.getMonth() + 1;
                              let dd = startdate.getDate();                            
                              if (dd < 10) dd = '0' + dd;
                              if (mm < 10) mm = '0' + mm;      
                              let formattedStartdate = yyyy + '-' + mm + '-' + dd;
                              formik.setFieldValue('startdate', formattedStartdate);
                          }}
                          error = {formik.touched.startdate && Boolean(formik.errors.startdate)}
                          errorenddate = {formik.touched.enddate && Boolean(formik.errors.enddate)}
                          
                          helperText = {formik.touched.startdate && formik.errors.startdate}
                          helperTextenddate = {formik.touched.enddate && formik.errors.enddate}
                          onChange1={(value) => {
                            let enddate = value.$d
                            let yyyy = enddate.getFullYear();
                            let mm = enddate.getMonth() + 1;
                            let dd = enddate.getDate();                            
                            if (dd < 10) dd = '0' + dd;
                            if (mm < 10) mm = '0' + mm;                            
                            let formattedEnddate = yyyy + '-' + mm + '-' + dd;
                            formik.setFieldValue('enddate', formattedEnddate);
                          }}
                          onChange2={posSearchOnChange}  
                          onChangeCheck={onChangeCheck}                
                          value={formik.values.startdate} 
                          value1={formik.values.enddate}
                          value2={formik.values.posname}
                          date_type_analytics={formik.values.date_type_analytics}
                          onSubmit={formik.handleSubmit}
                          checkSelectAll={checkSelectAll}
                          click={handleClose}
                          posList={posList}
                          posSelectAll={posSelectAll}
                          posLoading={posLoading}
                          handlePOSSearch={handlePOSSearch}
                          posNextPageUrl={posNextPage}
                          loadMore={loadMore}
                          checkedPosList={checkedPosList}
                          posSearch={posSearch}
                          formik={formik}
                          
                      />}
          />
          <AnalyticsModal 
              handleclose={scheduleModalClose} 
              open={scheduleModal} 
              scrollBottom={scrollBottom}
              content={scheduleContent}
          />
                        
      </>
    )
  }
    if(user.is_workspace_graph === true){
    return (
      <>
      <Toaster />
      <ExporterButton onClick={() => exporterButtonClick()} style={{marginTop: -45}}>
          {t('analyticsExporter.AnalyticsExporter')}
        </ExporterButton>
    
      <ExporterButton onClick={() => scheduledBtnClick()} style={{marginTop: -45, marginRight: 20}}>
          {t('analyticsExporter.ScheduledReportDelivery')}
        </ExporterButton>
        <AnalyticsModal 
              handleclose={handleClose} 
              open={open} 
              scrollBottom={scrollBottom}
              content={<ExporterModal onChange={(value) => {
                              let startdate = value.$d
                              let yyyy = startdate.getFullYear();
                              let mm = startdate.getMonth() + 1;
                              let dd = startdate.getDate();                            
                              if (dd < 10) dd = '0' + dd;
                              if (mm < 10) mm = '0' + mm;      
                              let formattedStartdate = yyyy + '-' + mm + '-' + dd;
                              formik.setFieldValue('startdate', formattedStartdate);
                          }}
                          error = {formik.touched.startdate && Boolean(formik.errors.startdate)}
                          errorenddate = {formik.touched.enddate && Boolean(formik.errors.enddate)}
                          
                          helperText = {formik.touched.startdate && formik.errors.startdate}
                          helperTextenddate = {formik.touched.enddate && formik.errors.enddate}
                          onChange1={(value) => {
                            let enddate = value.$d
                            let yyyy = enddate.getFullYear();
                            let mm = enddate.getMonth() + 1;
                            let dd = enddate.getDate();                            
                            if (dd < 10) dd = '0' + dd;
                            if (mm < 10) mm = '0' + mm;                            
                            let formattedEnddate = yyyy + '-' + mm + '-' + dd;
                            formik.setFieldValue('enddate', formattedEnddate);
                          }}
                          onChange2={posSearchOnChange}  
                          onChangeCheck={onChangeCheck}                
                          value={formik.values.startdate} 
                          value1={formik.values.enddate}
                          value2={formik.values.posname}
                          date_type_analytics={formik.values.date_type_analytics}
                          onSubmit={formik.handleSubmit}
                          checkSelectAll={checkSelectAll}
                          click={handleClose}
                          posList={posList}
                          posSelectAll={posSelectAll}
                          posLoading={posLoading}
                          handlePOSSearch={handlePOSSearch}
                          posNextPageUrl={posNextPage}
                          loadMore={loadMore}
                          checkedPosList={checkedPosList}
                          posSearch={posSearch}
                          formik={formik}
                          
                      />}
          />
          <AnalyticsModal 
              handleclose={scheduleModalClose} 
              open={scheduleModal} 
              scrollBottom={scrollBottom}
              content={scheduleContent}
          />
                        
      </>
    )}
    }
  }