import React from 'react'

import { Button,Menu,MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { translateChange } from 'redux/actions/language/languageActions';
import { useDispatch } from "react-redux"
import {useTranslation} from "react-i18next";

const CustomButton = styled(Button)(({ theme }) => ({
    // border:"1px solid #B8C5D3 !important",
    // width:123,
    // color:"#627080 !important",
    // font:"normal normal bold 18px/23px IBM Plex Sans",
    // backgroundColor:"#FFFFFF !important",
    width:"100%",
    maxWidth: 121,
    minWidth:121,
    height: 43,
    backgroundColor: "#FFFFFF !important",
    border: "1px solid #36333D !important",
    borderRadius: 5,
    color:"#36333D !important",
    fontFamily: "Roboto !important",
    fontSize:16,
    fontWeight:400,
    padding:"12px 12px 12px 23px",
    display:"flex",
    alignItems:"space-between",
    justifyContent:"space-between",
    textTransform:"capitalize",
    marginTop: "unset !important",
    "& .icon":{
        backgroundColor:"red"
    },
    [theme.breakpoints.down("xl")]:{
        maxWidth: 111,
        minWidth:111,
        height:40,
        fontSize:14,
        padding:"9px 9px 9px 20px",
     }
}))

const CustomMenu = styled(Menu)(({theme}) => ({
    "& .MuiMenu-paper":{
        width:121,
        marginTop:2,
        backgroundColor: "#FFFFFF !important",
        border: "1px solid #36333D !important",
        borderRadius: 5,
    },
    "& .MuiMenu-list":{
        color:"#36333D !important",
        fontFamily:"Roboto",
        fontSize:16,
        fontWeight:400,
    },
    [theme.breakpoints.down("xl")]:{
        "& .MuiMenu-paper":{
            width:111,
        },
        "& .MuiMenu-list":{
            fontSize:14
        },
     }
}))

export default function CustomSelect({label,options,from="filter"}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedOption, SetSelectedOption] = React.useState(null);
    const [t, i18n] = useTranslation('common');
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectOption = (option) => () => {
        SetSelectedOption(option.label);
        i18n.changeLanguage(option.code)
        dispatch(translateChange(option))
        handleClose();
    };

  return (
    <React.Fragment>
        <CustomButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon fontSize='large'/>}
        >
            {selectedOption?selectedOption:label}
        </CustomButton>
        <CustomMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {options.map((item, keyval) => {
                return(
                    <MenuItem onClick={handleSelectOption(item)} key={keyval}>{item.label}</MenuItem>
                )
            })}
        </CustomMenu>
    </React.Fragment>
  )
}
