import React,{useState,useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable} from 'components'
import { useTheme } from '@mui/material/styles';
import { Button, useMediaQuery,Grid } from "@mui/material";
import Modals from 'components/Modal';
import { useFormik } from "formik";
import { makeStyles } from '@mui/styles';
import styles from "./style";
import { styled } from '@mui/system';
import InputFile from 'components/InputFile';
import DetailsHead from 'components/DetailsHead';
import { useParams } from "react-router-dom";
import { getBucketData, uploadXdata, downloadSnapshot, deleteDbVersion } from "../../redux/actions/xdata/bucketActions"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "reactstrap"
import toast, { Toaster } from 'react-hot-toast';
import Accordion from '@mui/material/Accordion';
import { AiOutlineCloseCircle} from 'react-icons/ai';
import {useTranslation} from "react-i18next";
import {RiErrorWarningLine} from "react-icons/ri";
import { api_settings } from "../../redux/services/api";
import Swal from 'sweetalert2';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


  
  const Container = styled('div')(({ theme,height }) => ({
      position:"relative",
      width:"100%",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 2px 10px #00000029",
      borderRadius: 5,
      opacity: 1,
      display:"flex",
      minHeight:height,
      flexDirection:"column",
      "& .box-container":{
          padding:"20px 36px"
      },
      "& .text-color": {
          fontSize: 16,
          color: '#C8102E',
          marginTop: 20
      },
      '& .box-title':{
          flex:1,
          color:"#36333D",
          fontFamily:"Roboto",
          fontWeight:700,
          fontSize:14,
          [theme.breakpoints.up("xl")]:{
            fontSize: 20,
         },
         [theme.breakpoints.down("xl")]:{
          fontSize: 15,
       },
      },
      [theme.breakpoints.down('xl')]: {
          "& .box-container":{
              padding:"15px 31px"
          },
      }
  }))

  const useStyles = makeStyles(styles)

export default function XDataBucketDetail() {
  const {t} = useTranslation('common');
  const thead = [
    {
      name:"name",
      displayName:t('XdataBucketDetail.Name'),
      type:"text",
      latestTag: true,
      width:90,  //percentage
    },
    {
      name:"actions",
      displayName:t('XdataBucketDetail.Actions'),
      type:"action",
      width:10
    },
]
  const theme = useTheme();
  const classes = useStyles();
  const [errorModal, setErrorModal] = useState(false);
  const [uploadErrorData, setUploadErrorData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentDbVersion, setCurrentDbVersion] = useState(false);
  let { code } = useParams();
  const dispatch = useDispatch();
  const { 
    loading, 
    bucketDataSuccess, 
    uploadLoading, 
    uploadError, 
    uploadSuccess, 
    downloadSuccess,
    downloadLoading, 
    downloadError,
    upload500Error,
    download500Error,
    bucketDataError,
    deleteDbVersionDetails,
    deleteDbVersionError500,
   } = useSelector((state) => state.xdata.buckets);
  
  let linkData_default = [ 
    {
        displayName:t('XdataBucketDetail.XData'),
        link:"/x-data"
    },
    {
        displayName:t('XdataBucketDetail.Buckets'),
        link:"/dashboard/xdata/buckets",
        linkClick:true
    }
  ];
  const [linkData, setLinkData] = useState(linkData_default);
  const [bucketName, setBucketName] = useState(null);
  const [bucketCode, setBucketCode] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [snapshot, setSnapshot] = useState([]);
  const [downloadingItem, setDownloadingItem] = useState(null);
  const [latestTintVersion, setLatestTintVersion] = useState('--');
  const [mandatoryUpdateStatus, setMandatoryUpdateStatus] = useState(false);
  const [twoWayUpdateStatus, setTwoWayUpdateStatus] = useState(false);
 
  const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));
  const handleRowClick = (item) => () => {
    //
  }

  const handleDeleteClick = (item) => () =>{
    if(snapshot[0] !== item){      
    setCurrentDbVersion(item)
    setDeleteModal(!deleteModal)
    }
  }

  const deleteContent = (
    <>
    <div className={classes.logo1}>
        <RiErrorWarningLine color="#C8102E" size={"100px"} />
    </div>
    <div className={classes.warning}>
        {t('XdataBucketDetail.areYouSure')}
    </div>
    <div className={classes.text} >
        {t('XdataBucketDetail.deleteConfirmation')}
    </div>
    <div className={classes.button2}>
            <Button  
            onClick={()=> {
              setCurrentDbVersion()
              setDeleteModal(!deleteModal)}}  
              className='appBtnCancel'                                 
              size="large"
            >
               {t('XdataBucketDetail.cancel')}
            </Button>
            <div className={classes.ok}>
                <Button  
                className='appBtn'                       
                  size="large"
                  onClick={() =>   dispatch(deleteDbVersion(currentDbVersion))}
                >
                    {t('XdataBucketDetail.ok')}
                </Button>
            </div>
    </div>
  </>     
  )



  const [anchorElTemp, setAnchorElTemp] = useState(null);
  const [page, setPage] = useState(1)
  const [allusersCount, setAllUsersCount] = useState(0);

  const handleClick = (event) => {
    setAnchorElTemp(event.currentTarget);
  };

  // const handleDownloadclick = (item) => () => {
  //   let newDict = JSON.parse(JSON.stringify(item))
  //   if(item) {
  //       Swal.fire({
  //           title: t('XdataBucketDetail.download'),
  //           text: '',
  //           icon: 'info',
  //           iconColor: '#C8102E',
  //           input: 'checkbox',
  //           inputAttributes: {
  //             style: 'border-color: #f32121; background-color: #f32121;', // Customize the checkbox color
  //           },
  //           inputPlaceholder: t('XdataBucketDetail.selectDatabase'),
  //           confirmButtonColor: '#C8102E',
  //           confirmButtonText: t('XdataBucketDetail.ok'),
  //         }).then((result) => {
  //           if (result.isConfirmed) {
  //             if (result.value) {
  //                   newDict['version_support_encryption'] = true
  //                   console.log("download : ",newDict)
  //                   setDownloadingItem(newDict)
  //                   dispatch(downloadSnapshot(newDict))
  //               Swal.fire({
  //                 icon: 'success', iconColor: '#C8102E', 
  //                 text: 'You have downloaded encrypted databse!',
  //                 confirmButtonColor: '#C8102E',
  //                 confirmButtonText: t('XdataBucketDetail.ok')});
  //             } else {
  //                   newDict['version_support_encryption'] = false
  //                   console.log("download : ",newDict)
  //                   setDownloadingItem(newDict)
  //                   dispatch(downloadSnapshot(newDict))
  //               Swal.fire({
  //                 icon: 'success', 
  //                 iconColor: '#C8102E', 
  //                 text: "You have downloaded decrypted databse!",
  //                 confirmButtonColor: '#C8102E',
  //                 confirmButtonText: t('XdataBucketDetail.ok')
  //               });
  //             }
  //           } else {
  //             Swal.fire({
  //               icon: 'error', 
  //               iconColor: '#C8102E', 
  //               text: "Somthing went wrong !",
  //               confirmButtonColor: '#C8102E',
  //               confirmButtonText: t('XdataBucketDetail.ok')
  //             });
  //           }
  //         })
  //       }
  //   // newDict['version_support_encryption'] = false
  //   // console.log("download : ",newDict)
  //   // setDownloadingItem(newDict)
  //   // dispatch(downloadSnapshot(newDict))
  // }

  const handleDownloadclick = (item) => () => {
    let newDict = JSON.parse(JSON.stringify(item))
    Swal.fire({
      title: t('XdataBucketDetail.download'),
      icon: 'info',
      iconColor: '#C8102E',
      // cancelButtonColor: '#DDDDDD',
      cancelButtonText: t('XdataBucketDetail.encryptedDB'),
      showCancelButton: true,
      confirmButtonColor: '#C8102E',
      confirmButtonText: t('XdataBucketDetail.decryptedDB'),
      
    }).then((result) => {
      if (result.isConfirmed) {
        newDict['version_support_encryption'] = false
        setDownloadingItem(newDict)
        dispatch(downloadSnapshot(newDict))
          Swal.fire({
            icon: 'success', 
            iconColor: '#C8102E', 
            text: t('XdataBucketDetail.DownloadDecryptedDB'),
            confirmButtonColor: '#C8102E',
            confirmButtonText: t('XdataBucketDetail.ok')
          });
      } else if (result.isDismissed) {
        newDict['version_support_encryption'] = true
        setDownloadingItem(newDict)
        dispatch(downloadSnapshot(newDict))
        Swal.fire({
          icon: 'success', iconColor: '#C8102E', 
          text: t('XdataBucketDetail.DownloadEncryptedDB'),
          confirmButtonColor: '#C8102E',
          confirmButtonText: t('XdataBucketDetail.ok')});
      }
    })
    // newDict['version_support_encryption'] = false
    // console.log("download : ",newDict)
    // setDownloadingItem(newDict)
    // dispatch(downloadSnapshot(newDict))
  }

  const errors = {};
  const validate = values => {
    if (values.xdatafile === "") {
      errors.name = t('XdataBucketDetail.PleaseSelectafile');
    }
    return errors;
  };
  
  const formik = useFormik({
    initialValues: {    
      xdatafile: "", 
      code: code
    },
    validate,
    
    onSubmit : (values)=> {
      dispatch(uploadXdata(values))  
     
    }
  });

  const errorBlockBtnClick = () => {
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage;
      paginationOffset = paginationPage;
    }
    let payload = {
      bucket_code: code,
      offset: paginationOffset,
      limit: 3
    }  
    dispatch(getBucketData(payload))
  }

  useEffect(() => {  
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage;
      paginationOffset = paginationPage;
    }
    let payload = {
      bucket_code: code,
      offset: paginationOffset,
      limit: 3
    }  
    dispatch(getBucketData(payload)) 
    
  }, [page])

  useEffect(() => {  
    if(uploadSuccess){
      toast.success(t('XdataBucketDetail.XDatauploadedsuccessfully'), {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      formik.resetForm()
      dispatch({
        type: "REQUEST_UPLOAD_XDATA_SUCCESS",
        payload: null
      });
      let payload = {
        bucket_code: code,
        offset: 0,
        limit: 3
      }  
      dispatch(getBucketData(payload)) 
    }

    if(uploadError) {
      setErrorModal(true)
      setUploadErrorData(uploadError)
      formik.resetForm()
      dispatch({
        type: "REQUEST_UPLOAD_XDATA_FAILED",
        payload: null
      })
    }
    
  }, [uploadSuccess, uploadError])

  useEffect(() => {  

    if(downloadError) {
      toast.error( downloadError.error === "XData file not found" ? t('XdataBucketDetail.XDatafilenotfound'):downloadError.error , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_DOWNLOAD_SNAPSHOT_FAILED",
        payload: null
      })
    }
    
  }, [downloadError])

  useEffect(() => {  
    if(bucketDataSuccess?.data){
      setAllUsersCount(bucketDataSuccess.count)
      linkData_default.push({
        displayName:bucketDataSuccess.data.bucket_info.bucket_name,
        link:"/x-data/buckets/"+bucketDataSuccess.data.bucket_info.bucket_code+"/"
      })
      setLinkData(linkData_default)
      setBucketName(bucketDataSuccess.data.bucket_info.bucket_name)
      setBucketCode(bucketDataSuccess.data.bucket_info.bucket_code)
      setCompanyName(bucketDataSuccess.data.bucket_info.company_name)
      setMandatoryUpdateStatus(bucketDataSuccess.data.bucket_info.mandatory_update)
      setTwoWayUpdateStatus(bucketDataSuccess.data.bucket_info.otp_security_update)
     
      let snapshot_ary = []
      if(bucketDataSuccess.data?.snapshot.length){
        (bucketDataSuccess.data.snapshot).map((item, index) => {
          if(index === 0){
            setLatestTintVersion(""+item.major+"."+item.minor+"")
          }
          snapshot_ary.push({name:t('XdataBucketDetail.Tintsystemversion')+": "+item.major+"."+item.minor+t('XdataBucketDetail.withlatestcompatibleresources'),code:code,major:item.major,minor:item.minor})
        })
      }
      else{
        setLatestTintVersion("--")
      }
      setSnapshot(snapshot_ary)
      dispatch({
        type: "REQUEST_BUCKET_DATA_SUCCESS",
        payload: null
      })
    }
  }, [bucketDataSuccess])

  useEffect(() => {  
    if(downloadSuccess){
      //   var base64replace1 = downloadSuccess.data.replace("b'", "");
      // var base64replace2 = base64replace1.replace("'", "");
      
      // download1(bucketCode+".xdata", base64replace2)
      // dispatch({
      //   type: "REQUEST_DOWNLOAD_SNAPSHOT_SUCCESS",
      //   payload: null
      // });
      // console.log("download", downloadSuccess)
      // const url = new Blob([downloadSuccess.data], { type: 'application/zip' });
      //     const downloadLink = document.createElement('a');
      //     downloadLink.href = window.URL.createObjectURL(url);
      //     // downloadLink.download = 'example.xlsx';

      //     // Append the link to the body and trigger a click
      //     document.body.appendChild(downloadLink);
      //     downloadLink.click();

      //     // Cleanup: Remove the link from the DOM
      //     document.body.removeChild(downloadLink);
      const downloadLink = document.createElement('a');
          downloadLink.href = api_settings.url_base + downloadSuccess.data.url;
          // downloadLink.download = 'example.xlsx';

          // Append the link to the body and trigger a click
          document.body.appendChild(downloadLink);
          downloadLink.click();
          dispatch({
            type: "REQUEST_DOWNLOAD_SNAPSHOT_SUCCESS",
            payload: null
          });
    }
    
  }, [downloadSuccess])

  useEffect(() => {  
    if(deleteDbVersionDetails?.status === 204) {
      toast.success(t('XdataBucketDetail.xdataDbVersion') + currentDbVersion.major+"."+currentDbVersion.minor + t('XdataBucketDetail.deleted'), {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      setCurrentDbVersion()
      setDeleteModal(!deleteModal)
      let payload = {
        bucket_code: code,
        offset: 0,
        limit: 3
      }  
      setPage(1)
      dispatch(getBucketData(payload))
      dispatch({
        type: "REQUEST_DATABASE_VERSION_DELETE_SUCCESS",
        payload: null
      })
    }

  }, [deleteDbVersionDetails])

  useEffect(() => {  
    if(upload500Error){
      toast.error(t('XdataBucketDetail.uploadFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_UPLOAD_XDATA_FAILED_500",
        payload: null
      })
    }

    if(download500Error){
      toast.error(t('XdataBucketDetail.downloadFailed'), {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_DOWNLOAD_SNAPSHOT_FAILED_500",
        payload: null
      })
    }

    if(deleteDbVersionError500){
      toast.error(t('XdataBucketDetail.deleteFailed')  , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_DATABASE_VERSION_DELETE_FAILED_500",
        payload: null
      })
    }
    
  }, [upload500Error, download500Error, deleteDbVersionError500])

  const download1 =(filename, data) =>  {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;base64,' + data);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
  }

  

  const errorContent = (
    <div style={{width: 500, fontFamily:"Roboto",}}>
    
    <div className={classes.logo1}>
        <AiOutlineCloseCircle color="#C8102E" size={"100px"} />
    </div>
    <Accordion style={{boxShadow: 'unset', background: "#f2dede", padding:15}}>
        <div >
            <p >{t('XdataBucketDetail.Databaseconversionfailed')}</p>
            <p style={{marginTop: 10}}>{uploadErrorData ?  uploadErrorData === "non_field_errors : Invalid file format: must be a zip archive"? t('XdataBucketDetail.Invalidfileformat') : uploadErrorData === "non_field_errors : Malformed XData: missing version" ? t('XdataBucketDetail.MalformedXData'):uploadErrorData.substring(uploadErrorData.indexOf(":") + 1) :null}</p>
            
                
            </div>
            
        </Accordion>
    <div className={classes.button2}>
           
            <div className={classes.ok}>
                <Button                         
                  size="large"
                  onClick={() => setErrorModal(false)}
                >
                    {t('XdataBucketDetail.ok')}
                </Button>
            </div>
    </div>
  </div>     
  )
  
  
  return (
    <DashboardLayout pageLoading={uploadLoading}>
      <Toaster />
      <BreadCrumb linkData={linkData}/>
        <DetailsHead
            column1={{label: t('XdataBucketDetail.BucketName'), value: bucketName}}
            column2={{label: t('XdataBucketDetail.BucketCode'), value: bucketCode}}
            column3={{label: t('XdataBucketDetail.MandatoryUpdateforClients'), switch: true, switchOn: mandatoryUpdateStatus, bucketCode: code}}
            column4={{label: t('XdataBucketDetail.Companyname'), value: companyName}}
            column5={{label: t('XdataBucketDetail.LatestTintsystemVersion'), value: latestTintVersion}}
            column6={{label: t('XdataBucketDetail.2FASecurityUpdateforClients'), switch: true, switchOn: twoWayUpdateStatus, bucketCode: code}}
            
        />

        <Container style={{marginTop: 20}}>
          <form onSubmit={formik.handleSubmit}>
            <Grid className='box-container' container direction="row" justifyContent="center" alignItems="center">
                <Grid className={classes.grid} item xs={12}>
                    <h3 className='box-title'>{t('XdataBucketDetail.XDataUpload')}</h3> 
                </Grid>                
                  <Grid item sm={6}>
                      <InputFile 
                          btnText={t('XdataBucketDetail.ChooseFile')}
                          formik={formik}
                          onBlur={formik.handleBlur} 
                          value={formik.values.xdatafile}
                          name="xdatafile"
                        />
                  </Grid>
                  <Grid item sm={6} style={{display: 'flex', justifyContent: 'end'}}>
                      <Button   
                        className="appBtn"  
                        style={{marginTop: "unset"}}              
                        size="small"
                        onClick={handleClick}
                        type={'submit'}
                      >
                        {uploadLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('XdataBucketDetail.UploadXData')} 
                      </Button> 
                      
                  </Grid>                
            </Grid>
          </form>
        </Container>

        <div style={{marginTop: 20}}>
            <CustomTable 
                loading={loading}
                totalPageCount={allusersCount}
                title ={t('XdataBucketDetail.Snapshot')}
                thead={thead}
                tdata={snapshot}
                maxRow={3}
                handleRowClick={handleRowClick}
                handleDownloadclick={handleDownloadclick}
                handleDeleteClick={handleDeleteClick}
                height={isSmallerDesktop?150:413}
                action={
                  {                        
                    default:["download", "delete"]
                  }
                }
                loadPagination={setPage}
                downloadLoader={downloadLoading} 
                currentItem ={downloadingItem}
                currentPage={page}
                clickableColumn={-1}
                errorStatus={bucketDataError}
                errorBlockBtnClick={errorBlockBtnClick}
                errorTopheight={"translateY(50%)"}
            />
            <Modals 
                handleclose={() => setDeleteModal(!deleteModal)} 
                open={deleteModal} 
                content={deleteContent}                   
            />
            <Modals 
                handleclose={() => setErrorModal(!errorModal)} 
                open={errorModal} 
                content={errorContent}                   
            />
        </div>
    </DashboardLayout>
    
  )
}