import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";
import {logoutRedirect} from '../logoutRedirect/logoutRedirectAction'

export const getAllAnalytics = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_ALL_ANALYTICS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.analytics + '?offset=' + payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_ALL_ANALYTICS_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_ALL_ANALYTICS_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const exportAnalytics = (payload, posSelectAll) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CREATE_ANALYTICS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.analytics + payload.analyticsCode +'/', {
        "date_type": payload.date_type_analytics,
        "start_date": payload.startdate,
        "end_date": payload.enddate,
        "pos_names": posSelectAll ? [] : payload.poslist
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CREATE_ANALYTICS_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CREATE_ANALYTICS_FAILED",
          payload: err.response
        })
      })
  }
}

export const getAllPOS = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_POS_LIST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    let searchParam = ''
    if(payload.search !== " "){ 
      searchParam += '?search='+payload.search
    }
    axios
      .get(api_settings.url_base + api_endpoints.analytics + payload.analyticsCode +'/'+ searchParam, config)
      .then(response => {
        dispatch({
          type: "REQUEST_POS_LIST_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_POS_LIST_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const getPaginationPOS = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_POS_LIST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }    
    axios
      .get(payload.page, config)
      .then(response => {
        dispatch({
          type: "REQUEST_POS_LIST_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_POS_LIST_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const getCCAnnualSalesTrend = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_ANNUALSALESTREND"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_AnnualSalesTrend + payload.code + '?type=' + payload.type, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_ANNUALSALESTREND_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_ANNUALSALESTREND_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCSalesVolume = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_ANNUALSALESVOLUME"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_AnnualSalesVolume + payload.code + '?start_date=' + payload.start_date + '&end_date=' + payload.end_date + '&type=' + payload.type, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_ANNUALSALESVOLUME_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_ANNUALSALESVOLUME_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCTopTenColors = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_TOPTENCOLORS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_TopTenColors + payload.code +  '?type=' + payload.type + '&date_type=' + payload.date_type, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_TOPTENCOLORS_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_TOPTENCOLORS_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCTopTenProducts = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_TOPTENPRODUCTS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_TopTenProducts + payload.code  + '/?type=' + payload.type + '&date_type=' + payload.date_type, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_TOPTENPRODUCTS_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_TOPTENPRODUCTS_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCTopCanSales = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_TOPCANSALES"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_TopCanSales + payload.code + '?date_type=' + payload.date_type, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_TOPCANSALES_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_TOPCANSALES_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCustomFormulaSales = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_CUSTOM_FORMULA_SALES"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_CustomFormulaSales + payload.code + '/', config)
      .then(response => {
       
        dispatch({
          type: "REQUEST_CC_CUSTOM_FORMULA_SALES_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_CUSTOM_FORMULA_SALES_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCMachineStat = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_MACHINE_STAT"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_MachineStat + payload.code + '/', config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_MACHINE_STAT_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_MACHINE_STAT_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCBucketAnalytics = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_BUCKET_ANALYTICS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics + payload.code + '/', config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_FAILED",
          payload: err.response.status
        })
      })
  }
}


export const getCCBucketAnalyticsNewShop = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_BUCKET_ANALYTICS_NEW_SHOP"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_NewShop + payload.code + '/', config)
      .then(response => {

        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_NEW_SHOP_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_NEW_SHOP_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCBucketAnalyticsVolumeTinted = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_BUCKET_ANALYTICS_VOLUME_TINTED"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_VolumeTinted + payload.code + '/', config)
      .then(response => {
        
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_VOLUME_TINTED_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_VOLUME_TINTED_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCBucketAnalyticsLocation = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_BUCKET_ANALYTICS_LOCATION"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_Location + payload.code + '/', config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_LOCATION_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_LOCATION_FAILED",
          payload: err.response.status
        })
      })
  }
}


export const getCCLocationBasedSales = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_BUCKET_ANALYTICS_LOCATION_BASED_SALES"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_locationBasedSale + payload.code + '/', config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_LOCATION_BASED_SALES_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_LOCATION_BASED_SALES_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCColorantConsumption = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_BUCKET_ANALYTICS_COLORANT_CONSUMPTION"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_colorant_consumption + payload.code + '/' + '?start_date=' + payload.start_date + '&end_date=' + payload.end_date, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_COLORANT_CONSUMPTION_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_COLORANT_CONSUMPTION_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCColorantConsumptionBar = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_BUCKET_ANALYTICS_COLORANT_CONSUMPTION_BAR"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.cc_bucketAnalytics_colorant_consumption_bar + payload.code + '/' + '?start_date=' + payload.start_date + '&end_date=' + payload.end_date+ '&type=' + payload.type + '&date_type=' + payload.date_type, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_COLORANT_CONSUMPTION_BAR_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_BUCKET_ANALYTICS_COLORANT_CONSUMPTION_BAR_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCTopTenSubProducts = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_TOPTENPRODUCTS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.c_bucketAnalytics_TopTenSubProducts + payload.code + '/?start_date=' + payload.start_date + '&end_date=' + payload.end_date + '&type=' + payload.type + '&date_type=' + payload.date_type, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_TOPTENPRODUCTS_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_TOPTENPRODUCTS_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCTopTenBaseProducts = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_TOPTENPRODUCTS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.c_bucketAnalytics_TopTenBaseProducts + payload.code + '/?start_date=' + payload.start_date + '&end_date=' + payload.end_date + '&type=' + payload.type + '&date_type=' + payload.date_type, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_TOPTENPRODUCTS_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_TOPTENPRODUCTS_FAILED",
          payload: err.response.status
        })
      })
  }
}


export const scheduledReportDelivery = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_SCHEDULED_REPORT_DELIVERY"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.cc_bucketAnalytics_scheduled_report_and_mail, payload, config)
      .then(response => {
        dispatch({
          type: "REQUEST_SCHEDULED_REPORT_DELIVERY_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        console.log("error", err)
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_SCHEDULED_REPORT_DELIVERY_FAILED",
          payload: err.response.status
        })
      })
  }
}


