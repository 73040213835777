import React,{useState,useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable } from 'components';
import ExporterModal from 'components/Datepicker/Exportermodal';
import AnalyticsModal from 'components/Modal/AnalyticsModal';
import { useFormik } from "formik";
import {  string} from "yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllAnalytics, exportAnalytics, getAllPOS, getPaginationPOS } from "../../redux/actions/analytics/analyticsActions"
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import {useTranslation} from "react-i18next";
import {search} from '../../components/Search/Search'




export default function XDataAnalyticsBuckets() {
  const {t} = useTranslation('common');
  const [searchText, setSearchText] = useState("");

    const linkData = [ 
      {
          displayName:t('XdataAnalytics.XData'),
          link:"/x-data"
      },
      {
        displayName:t('XdataAnalytics.Analytics'),
        link:"/x-data/buckets"
    },
    {
          displayName:t('XdataAnalytics.Buckets'),
          link:"/x-data/buckets"
      },
  ]

  const thead = [
    {
      name:"code",
      displayName:t('XdataAnalytics.Code'),
      type:"text",
      width:20
    },
      {
        name:"name",
        displayName:t('XdataAnalytics.Name'),
        type:"text",
        width:30,  //percentage
      },
      {
        name: "company_name",
        displayName: t('XdataAnalytics.Company'),
        type:"number",
        width:30
      },
      {
        name:"records",
        displayName:t('XdataAnalytics.Records'),
        type:"number",
        width:20
      },
  ]
    const [open, setOpen] = useState(false);
    const [analyticslist, setAnalyticslist] = useState([]);
    const [lastItem, setLastItem] = useState(null);
    const [posList, setPosList] = useState([]);
    const [posNextPage, setPosNextPage] = useState("");

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { loading,analytics,createSuccess, exportLoading, posSuccess, posLoading, error } = useSelector((state) => state.analytics.analytics);
    const [page, setPage] = useState(1)
    const [posPage, setPosPage] = useState(1)
    const [allusersCount, setAllUsersCount] = useState(0);
    const [posSelectAll, setPosSelectAll] = useState(false);


    const theme = useTheme();
    const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));

    
    const formik = useFormik({
        initialValues: {
        startdate: "",
        enddate: "",
        posname: "",   
        poslist: []    
      },
      validationSchema: Yup.object({
        startdate: string().required(t('XdataAnalytics.StartDateisrequired')),
        enddate: string().required(t('XdataAnalytics.Enddateisrequired')),                                                                
      }),
      onSubmit : (values)=> { 
        
        values.analyticsCode = lastItem;    
        dispatch(exportAnalytics(values)) 
     }
    });

    const getPosList = (itemcode) => {    
        let payload = {
          analyticsCode: itemcode,
          search: formik.values.posname
        }  
        
        dispatch(getAllPOS(payload))      
    }

    const handleClose = () =>{
      formik.resetForm()
      setOpen(false);
    }



    const handleRowClick = (item) => () => {
      navigate("/dashboard/xdata/analytics/"+item.code+"",{
        state:item
      })  
      // if(posSelectAll){
      //   setPosSelectAll(!posSelectAll)
      // }
      // setPosNextPage("")
      // formik.setFieldValue('poslist', [])
      // formik.setFieldValue('posname', "")   
      // setLastItem(item.code)
      // setPosList([])     
       
      // handleOpen()      
      // getPosList(item.code)
    }

    const checkSelectAll = () => {
      formik.setFieldValue('poslist', []) 
      setPosSelectAll(!posSelectAll)
    }

    const handlePOSSearch = () => {
      setPosNextPage("")
      setPosList([])
      getPosList(lastItem)
    }

    const onChangeCheck = (item) => () => {
      let formik_poslist = formik.values.poslist
      let already_in = false
      formik_poslist.map((itemv,keyi)=>{ 
        if(itemv===item.pos_name){
          already_in = true;
          formik_poslist.splice(keyi, 1);
        }
      })
      
      if(already_in){
        formik.setFieldValue('poslist', formik_poslist)
        formik.setFieldValue('posname', formik_poslist.join(','))
      }else{
        formik_poslist.push(item.pos_name)
        formik.setFieldValue('poslist', formik_poslist)
        formik.setFieldValue('posname', formik_poslist.join(','))
      }
    }

    const scrollBottom = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if(bottom){
        if(posSelectAll){}else{
          setPosPage(posPage+1)
          if(posNextPage !== ""){
            if(posNextPage === "" || posNextPage === null || posNextPage === undefined){

            }else{
              dispatch(getPaginationPOS({page:posNextPage}))   
            }          
          }else{
            getPosList(lastItem)
          }   
        }     
      }      
    }

    const errorBlockBtnClick = () => {
      let paginationPage = page;
      let paginationOffset = 0;
      if(page > 1){
        paginationPage = paginationPage - 1;
        paginationPage = paginationPage * 10;
        paginationOffset = paginationPage;
      }
      let payload = {
        offset: paginationOffset
      }
      dispatch(getAllAnalytics(payload))  
    }

    const loadMore = () => {
        if(posSelectAll){}else{
          setPosPage(posPage+1)
          if(posNextPage !== ""){
            if(posNextPage === "" || posNextPage === null || posNextPage === undefined){

            }else{
              dispatch(getPaginationPOS({page:posNextPage}))   
            }          
          }else{
            getPosList(lastItem)
          }   
        }       
    }
    


    useEffect(() => {  
      let paginationPage = page;
      let paginationOffset = 0;
      if(page > 1){
        paginationPage = paginationPage - 1;
        paginationPage = paginationPage * 10;
        paginationOffset = paginationPage;
      }
      let payload = {
        offset: paginationOffset
      }
      dispatch(getAllAnalytics(payload))  
    },[]);

    useEffect(() => {  
      if(analytics?.data){
        var searchResult =  search(analytics?.data, searchText)
        // console.log("searchResult", searchResult)
        if(searchResult && searchText !== ""){
          var firstIndex = (page * 10) - 10
          var lastIndex = (page * 10)
          const arrayFiltered = searchResult.slice(firstIndex, lastIndex);
          setAnalyticslist(arrayFiltered)
          setAllUsersCount(searchResult.length)
        }
        else{
            var firstIndex = (page * 10) - 10
            var lastIndex = (page * 10)
            const arrayFiltered = analytics.data.slice(firstIndex, lastIndex);
            setAnalyticslist(arrayFiltered)
            setAllUsersCount(analytics.data.length)
          }
        }
      },[page, analytics, searchText]);

      const onSearchChange = (e) => {
        setPage(1)
        setSearchText(e.target.value)
      }
  
      

    useEffect(() => {  
      if(posSuccess?.data){
        setPosNextPage(posSuccess?.next)
        if(posSuccess.data.length > 0){
          let prev_posList = posList
          posSuccess.data.map((item,index)=>{ 
            prev_posList.push(item)
          })
          setPosList(prev_posList)
        }else{
          setPosList([])
        }
        if(posPage === 1){
          formik.setFieldValue('poslist', [])
          formik.setFieldValue('posname', "")
        }
      }      
    },[posSuccess]);

    useEffect(() => { 
      if(createSuccess?.status === "success"){
        // setOpen(false)
        var base64replace1 = createSuccess.data.replace("b'", "");
        var base64replace2 = base64replace1.replace("'", "");

        var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        var blob1 = b64toBlob(base64replace2, contentType);
        var blobUrl1 = URL.createObjectURL(blob1);
        dispatch({
          type: "DOWNLOAD_RESET",
          payload: null
        })
        var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = blobUrl1;
          a.download = "xdata-history-export-" + lastItem + "-" + formik.values.startdate + " 00_00_00-" + formik.values.enddate + " 23_59_59";
          a.click();
      }   
    },[createSuccess])

    const b64toBlob =(b64Data, contentType, sliceSize) =>  {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;
      
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
      
        var byteArray = new Uint8Array(byteNumbers);
        
        byteArrays.push(byteArray);
      }
      
      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }

   
    

  return (
    <DashboardLayout pageLoading={exportLoading}>
        <BreadCrumb linkData={linkData}/> 
        <CustomTable 
            loading={loading}
            totalPageCount={allusersCount}
            title ={t('XdataAnalytics.XDatabucketanalytics')}
            thead={thead}
            tdata={analyticslist}
            maxRow={10}
            handleRowClick={handleRowClick}
            height={isSmallerDesktop?617:767}
            loadPagination={setPage}
            clickableColumn={0}
            errorStatus={error}
            errorBlockBtnClick={errorBlockBtnClick}
            onChange={onSearchChange}
            value={searchText}
            searchLabel={t('XdataAnalytics.searchBuckets')}
            search={true}
            currentPage={page}
        /> 
        <AnalyticsModal 
            handleclose={handleClose} 
            open={open} 
            scrollBottom={scrollBottom}
            content={<ExporterModal onChange={(value) => {
                            let startdate = value.$d
                            let yyyy = startdate.getFullYear();
                            let mm = startdate.getMonth() + 1;
                            let dd = startdate.getDate();                            
                            if (dd < 10) dd = '0' + dd;
                            if (mm < 10) mm = '0' + mm;      
                            let formattedStartdate = yyyy + '-' + mm + '-' + dd;
                            formik.setFieldValue('startdate', formattedStartdate);
                        }}
                        error = {formik.touched.startdate && Boolean(formik.errors.startdate)}
                        errorenddate = {formik.touched.enddate && Boolean(formik.errors.enddate)}
                        
                        helperText = {formik.touched.startdate && formik.errors.startdate}
                        helperTextenddate = {formik.touched.enddate && formik.errors.enddate}
                        onChange1={(value) => {
                          let enddate = value.$d
                          let yyyy = enddate.getFullYear();
                          let mm = enddate.getMonth() + 1;
                          let dd = enddate.getDate();                            
                          if (dd < 10) dd = '0' + dd;
                          if (mm < 10) mm = '0' + mm;                            
                          let formattedEnddate = yyyy + '-' + mm + '-' + dd;
                          formik.setFieldValue('enddate', formattedEnddate);
                        }}
                        onChange2={formik.handleChange}  
                        onChangeCheck={onChangeCheck}                
                        value={formik.values.startdate} 
                        value1={formik.values.enddate}
                        value2={formik.values.posname}
                        onSubmit={formik.handleSubmit}
                        checkSelectAll={checkSelectAll}
                        click={handleClose}
                        posList={posList}
                        posSelectAll={posSelectAll}
                        posLoading={posLoading}
                        handlePOSSearch={handlePOSSearch}
                        posNextPageUrl={posNextPage}
                        loadMore={loadMore}
                    />}
        /> 
            
       
    </DashboardLayout>
  )
}
