import React, { useState, useEffect, useRef } from 'react';
import { DashboardLayout } from 'layouts';
import { BreadCrumb, CustomTable, ErrorBlock } from 'components';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import styles from "./style";
import DashboardCard from 'components/DashboardCard';
import AreaChart from 'components/eCharts/AreaChart';
import BarChart from 'components/eCharts/BarChart';
import PieChart from 'components/eCharts/PieChart';
import FunnelChart from 'components/eCharts/FunnelChart';
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { DateRangePicker } from "mui-daterange-picker";
import { ReactComponent as ShopIcon } from "assets/icons/shop.svg";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import CircularProgress from '@mui/material/CircularProgress';
import {BiInfoCircle} from 'react-icons/bi';
import {AiOutlineReload} from 'react-icons/ai';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  getCCLocationAnalyticCustomFormula, 
  getCCLocationAnalyticsAvgLocationFootfall, 
  getCCLocationAnalyticsColorantConsumption, 
  getCCLocationAnalyticsInformation, 
  getCCLocationAnalyticsMachineStat, 
  getCCLocationAnalyticsTopBases, 
  getCCLocationAnalyticsTopProducts, 
  getCCLocationAnalyticsTopSubProducts, 
  getCCLocationAnalyticsTopTenColors, 
  getCCLocationAnalyticsVolumeOrder, 
  getCCLocationAnalyticTotalSales, 
  getCCLocationAnalyticsColorantConsumptionBar
} from 'redux/actions/analytics/locationAnalyticsAction';
import UseIntersection from '../../elements/UseIntersection'
import {useTranslation} from "react-i18next";
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const useStyles = makeStyles(styles)

const Refresh = styled('div')(({ theme }) => ({
  background: '#c8102e',
  borderRadius: 5, 
  flex: 'none', 
  fontFamily: 'Roboto', 
  cursor: 'pointer', 
  fontSize: 12,
  width: 'fit-content',
  color: '#fff',
  marginRight: '10px',
  "&:hover":{
    background: "#eb314f",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"
  },
  [theme.breakpoints.up("xl")]:{
    fontSize:14,
  }
}))

const FirstSection = styled('div')(({ theme,height }) => ({
  position:"relative",
  width:"100%",
  height: 'auto',
  display:"-webkit-box",
}))

const WhiteCard = styled('div')(({ theme,height }) => ({
  position:"relative",
  width:"100%",
  height: 500,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 2px 10px #00000029',
  borderRadius: 5,
  padding: '2% 3% 2% 3%',
  marginBottom: '2%'
}))

const DoubleWhiteCard = styled('div')(({ theme,height }) => ({
  position:"relative",
  width:"100%",
  height: 'auto',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 2px 10px #00000029',
  borderRadius: 5,
  padding: '2% 3% 2% 3%',
  marginBottom: '2%'
}))

const ChartButton = styled('span')(({ theme, clickDisable }) => ({
  background: '#c8102e', 
  padding: 10, 
  borderRadius: 5, 
  fontFamily: 'Roboto', 
  cursor: 'pointer',
  color: '#fff',
  fontSize: 12,
  marginLeft: '2%',
  pointerEvents: clickDisable ? "none": 'all',
  "&:hover":{
    background: "#eb314f",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"
},
})) 

const ChartButtonWithoutBg = styled('div')(({ theme }) => ({
  background: '#fff', 
  padding: 10, 
  borderRadius: 5, 
  font: 'normal normal normal 14px/15px Roboto',
  border: '1px solid #C8102E',
  cursor: 'pointer', 
  color: '#36333D',
  fontSize: 12,
  textAlign: 'center',
  marginRight: 13,
  "&:hover":{
    background: "#C8102E",
    color: "#fff"
},
})) 

const ChartButtonActive = styled('div')(({ theme }) => ({
  background: '#C8102E', 
  padding: 10, 
  borderRadius: 5, 
  font: 'normal normal normal 14px/15px Roboto',
  border: '1px solid #C8102E',
  cursor: 'pointer', 
  color: '#fff',
  fontSize: 12,
  textAlign: 'center',
  marginRight: 13
})) 



export default function Dashboard() {
  const formatDate = (datestring, type, dateIncrement) => {
    function pad2(n) {
        return (n < 10 ? '0' : '') + n;
      }
      var date 
      if(dateIncrement === "sales"){
        var timeStap = new Date(datestring)
        date = new Date(timeStap.setDate(timeStap.getDate() + 1))
      }
      else{
        date = new Date(datestring)
      }
      var month = pad2(date.getMonth()+1);//months (0-11)
      var day = pad2(date.getDate());//day (1-31)
      var year= date.getFullYear();
      var formattedDate
      if(type === "date") {
        formattedDate =  year +"-"+ month+"-"+ day
      }
      else{
        formattedDate =  day +"/"+ month+"/"+ year
        
      }
        return formattedDate; //03/02/2021
  }
  const {t} = useTranslation('common');
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const linkData = [ 
    {
        displayName: t('xdataLocationDetails.xdata'),
        link:"/x-data"
    },
    {
        displayName:t('XdataAnalyticsDetail.Analytics'),
        link:"/dashboard/xdata/analytics",
        linkClick:true
    },
    {
      displayName: t('xdataLocationDetails.locations'),
      link:"/dashboard/xdata/analytics/locations/",
      linkClick:true
    },
    {
      displayName: location.state.pos_name,
      link:"/xdata/analytics"
    }
  ]

  const thead = [
    {
      name:"dispenser_serial",
      displayName: t('xdataLocationDetails.dispenserSerial'),
      type:"text",
      width:12,  //percentage
    },
    {
      name:"pos_type",
      displayName: t('xdataLocationDetails.posType'),
      type:"text",
      width:12
    },
    {
      name:"dispensing_timestamp",
      displayName: t('xdataLocationDetails.dispensingTimestamp'),
      type:"timestamp",
      width:12
    },
    {
      name:"dispenser_family",
      displayName: t('xdataLocationDetails.dispenserFamily'),
      type:"text",
      width:12
    },
    {
      name:"tintsystem_description",
      displayName: t('xdataLocationDetails.tintsystemDescription'),
      type:"text",
      width:12
    },
    {
      name:"tintsystem_major",
      displayName: t('xdataLocationDetails.tintsystemMajor'),
      type:"text",
      width:12
    },
    {
      name:"tintsystem_minor",
      displayName: t('xdataLocationDetails.tintsystemMinor'),
      type:"text",
      width:12
    },
    {
      name:"Machine_status",
      displayName: t('xdataLocationDetails.machineStatus'),
      type:"machineStatus",
      width:15
    }
  ]

  const { 
    ccLocationTotalSalesSuccess, 
    ccLocationCustomFormulaSuccess,
    ccLocationVolumeOrderSuccess,
    ccLocationInformationSuccess,
    ccLocationTopTenColorsSuccess,
    ccLocationTopProductsSuccess,
    ccLocationAvgLocationFootfallSuccess,
    ccLocationMachineStatSuccess,
    ccLocationMachineStatLoading,
    ccLocationColorantConsumptionSuccess,
    ccLocationTopBasesSuccess,
    ccLocationTopSubProductsSuccess,
    ccLocationInformationLoading,
    ccLocationVolumeOrderLoading,
    ccLocationTotalSalesLoading,
    ccLocationCustomFormulaLoading,
    ccLocationTopTenColorsLoading,
    ccLocationTopProductsLoading,
    ccLocationTopSubProductsLoading,
    ccLocationTopBasesLoading,
    ccLocationColorantConsumptionLoading,
    ccLocationAvgLocationFootfallLoading,
    ccLocationInformationError,
    ccLocationVolumeOrderError,
    ccLocationTotalSalesError,
    ccLocationCustomFormulaError,
    ccLocationTopTenColorsError,
    ccLocationTopProductsError,
    ccLocationTopSubProductsError,
    ccLocationTopBasesError,
    ccLocationColorantConsumptionError,
    ccLocationAvgLocationFootfallError,
    ccLocationMachineStatError,
    ccLocationColorantConsumptionBarSuccess,
    ccLocationColorantConsumptionBarError,
    ccLocationColorantConsumptionBarLoading

  } = useSelector((state) => state.analytics.locations);
  
  const [annualSalesVolumeXaxis, setAnnualSalesVolumeXaxis] = useState([]);
  const [annualSalesVolumeYaxis, setAnnualSalesVolumeYaxis] = useState([]);

  const [annualSalesVolumeDownload, setAnnualSalesVolumeDownload] = useState([]);
  const [topTenColorDownload, setTopTenColorDownload] = useState([]);
  const [topTenProductDownload, setTopTenProductDownload] = useState([]);
  const [topTenSubProductDownload, setTopTenSubProductDownload] = useState([]);
  const [topTenBaseDownload, setTopTenBaseDownload] = useState([]);
  const [locationAnalyticsSyncDate, setLocationAnalyticsSyncDate] = useState([]);

  const [salesVolumeLitreOrOrder, setSalesVolumeLiterOrOrder] = useState(true);
  const [topStatisticsLitreOrOrder, setTopStatisticsLitreOrder] = useState(true);
  
  
  const [colorantConsumptionDownload, setColorantConsumptionDownload] = useState([]);

  //Colorant Consumption Bar
  const [colorantConsumptionBarDownload, setColorantConsumptionBarDownload] = useState([]);
  const [colorantConsumptionBarColor, setColorantConsumptionBarColor] = useState([]);

  

  const [topTenColors, setTopTenColors] = useState([
    '#810015', '#FF2C4F', '#76737D', '#FF637D',  '#A8001B', '#FF99A9',  '#C70021', '#FFB9C5',  '#E30025', '#36333D'
  ]);
  const [topTenColorsData, setTopTenColorsData] = useState([]);
  const [topTenProductsYaxis, setTopTenProductsYaxis] = useState([]);
  const [topTenProductsData, setTopTenProductsData] = useState([]);
  const [topTopCanSalesXaxis, setTopTopCanSalesXaxis] = useState([]);
  const [topCanSalesData, setTopCanSalesData] = useState([]);
  const [machineStatData, setMachineStatData] = useState([]);
  const [colorantConsumptionXAxis, setColorantConsumptionXAxis] = useState([]);
  const [colorantConsumptionSeries, setColorantConsumptionSeries] = useState([]);
  //Colorant Consumption Bar
  const [colorantConsumptionBarXAxis, setColorantConsumptionBarXAxis] = useState([]);
  const [colorantConsumptionBarSeries, setColorantConsumptionBarSeries] = useState([]);

  const [activeName, setActiveName] = useState("product");
  const [activationDays, setActivationDays] = useState();

  //new
  const [volumeOrders, setVolumeOrders] = useState();
  const [customFormulaSales, setCustomFormulaSales] = useState();
  const [locationAnalyticsInformation, setLocationAnalyticsInformation] = useState();

  const [openTotalSalesVolume, setOpenTotalSalesVolume] = useState(false);
  const toggleTotalSalesVolume = () => setOpenTotalSalesVolume(!openTotalSalesVolume); 
  // const [totalSalesVolumeStartDate, setTotalSalesVolumeStartDate] = useState(changeDateFormat(new Date(new Date().setDate(new Date().getDate() - 7))));
  // const [totalSalesVolumeEndDate, setTotalSalesVolumeEndDate] = useState(changeDateFormat(new Date()));
  const [totalSalesVolumeStartDate, setTotalSalesVolumeStartDate] = useState({formattedDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "date", "sales"), formattedStandardDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "datestandard", "sales")});
  const [totalSalesVolumeEndDate, setTotalSalesVolumeEndDate] = useState({formattedDate: formatDate(new Date().setDate(new Date().getDate()), "date", "sales1"), formattedStandardDate: formatDate(new Date().setDate(new Date().getDate()), "datestandard", "sales1")});
  function setDateRangeTotalSalesVolume(dateRangeTotalSalesVolume){
    let endDate = changeDateFormat(dateRangeTotalSalesVolume.endDate);
    setTotalSalesVolumeEndDate(endDate);
    let startDate = changeDateFormat(dateRangeTotalSalesVolume.startDate);
    setTotalSalesVolumeStartDate(startDate);
    toggleTotalSalesVolume()
    let payload_analytics_total_sales = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: salesVolumeLitreOrOrder === true ? "order": "litre",
      start_date: startDate?.formattedDate,
      end_date: endDate?.formattedDate
    }
    dispatch(getCCLocationAnalyticTotalSales(payload_analytics_total_sales))
  }

  const [openTotalSalesStatistics, setOpenTotalSalesStatistics] = useState(false);
  const toggleTotalSalesStatistics = () => setOpenTotalSalesStatistics(!openTotalSalesStatistics); 
  // const [totalSalesStatisticsStartDate, setTotalSalesStatisticsStartDate] = useState(changeDateFormat(new Date(new Date().setDate(new Date().getDate() - 7))));
  // const [totalSalesStatisticsEndDate, setTotalSalesStatisticsEndDate] = useState(changeDateFormat(new Date()));
  const [totalSalesStatisticsStartDate, setTotalSalesStatisticsStartDate] = useState({formattedDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "date", "sales"), formattedStandardDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "datestandard", "sales")});
  const [totalSalesStatisticsEndDate, setTotalSalesStatisticsEndDate] = useState({formattedDate: formatDate(new Date().setDate(new Date().getDate()), "date", "sales1"), formattedStandardDate: formatDate(new Date().setDate(new Date().getDate()), "datestandard", "sales1")});
  function setDateRangeTotalSalesStatistics(dateRangeTotalSalesStatistics){
    setActiveName("product")
    let endDate = changeDateFormat(dateRangeTotalSalesStatistics.endDate);
    setTotalSalesStatisticsEndDate(endDate);
    let startDate = changeDateFormat(dateRangeTotalSalesStatistics.startDate);
    setTotalSalesStatisticsStartDate(startDate);
    toggleTotalSalesStatistics()
    let payload_total_sales_statistics = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      start_date: startDate?.formattedDate,
      end_date: endDate?.formattedDate
    }
    dispatch(getCCLocationAnalyticsTopProducts(payload_total_sales_statistics))
    dispatch(getCCLocationAnalyticsTopTenColors(payload_total_sales_statistics))
  }

  const [openColorantConsumption, setOpenColorantConsumption] = useState(false);
  const toggleColorantConsumption = () => setOpenColorantConsumption(!openColorantConsumption); 
  // const [colorantConsumptionStartDate, setColorantConsumptionStartDate] = useState(changeDateFormat(new Date(new Date().setDate(new Date().getDate() - 7))));
  // const [colorantConsumptionEndDate, setColorantConsumptionEndDate] = useState(changeDateFormat(new Date()));
  const [colorantConsumptionStartDate, setColorantConsumptionStartDate] = useState({formattedDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "date"), formattedStandardDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "datestandard")});
  const [colorantConsumptionEndDate, setColorantConsumptionEndDate] = useState({formattedDate: formatDate(new Date().setDate(new Date().getDate() - 1), "date"), formattedStandardDate: formatDate(new Date().setDate(new Date().getDate() - 1), "datestandard")});
  function setDateRangeColorantConsumption(dateRangeColorantConsumption){
    let endDate = changeDateFormat(dateRangeColorantConsumption.endDate);
    setColorantConsumptionEndDate(endDate);
    let startDate = changeDateFormat(dateRangeColorantConsumption.startDate);
    setColorantConsumptionStartDate(startDate);
    toggleColorantConsumption()
    let payload_colorant_consumption = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      start_date: startDate?.formattedDate,
      end_date: endDate?.formattedDate
    }
    dispatch(getCCLocationAnalyticsColorantConsumption(payload_colorant_consumption))
  }

  //Colorant Consumption Bar
  const [openColorantConsumptionBar, setOpenColorantConsumptionBar] = useState(false);
  const toggleColorantConsumptionBar = () => setOpenColorantConsumptionBar(!openColorantConsumptionBar); 

  const [colorantConsumptionBarStartDate, setColorantConsumptionBarStartDate] = useState({formattedDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "date"), formattedStandardDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "datestandard")});
  const [colorantConsumptionBarEndDate, setColorantConsumptionBarEndDate] = useState({formattedDate: formatDate(new Date().setDate(new Date().getDate() - 1), "date"), formattedStandardDate: formatDate(new Date().setDate(new Date().getDate() - 1), "datestandard")});
  function setDateRangeColorantConsumptionBar(dateRangeColorantConsumptionBar){
    let endDate = changeDateFormat(dateRangeColorantConsumptionBar.endDate);
    setColorantConsumptionBarEndDate(endDate);
    let startDate = changeDateFormat(dateRangeColorantConsumptionBar.startDate);
    setColorantConsumptionBarStartDate(startDate);
    toggleColorantConsumptionBar()
    let payload_colorant_consumption_Bar = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      start_date: startDate?.formattedDate,
      end_date: endDate?.formattedDate
    }
    dispatch(getCCLocationAnalyticsColorantConsumptionBar(payload_colorant_consumption_Bar))
  }

  function changeDateFormat(date){ 
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedDate = yyyy + '-' + mm + '-' + dd;
    const formattedStandardDate = dd + '/' + mm + '/' + yyyy;
    
    return {
      'formattedDate': formattedDate,
      'formattedStandardDate': formattedStandardDate
    };
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {  
    let payload_top_ten_products = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      start_date: totalSalesStatisticsStartDate?.formattedDate,
      end_date: totalSalesStatisticsEndDate?.formattedDate
    }
    if(activeName === "product"){
      if(isLoadlocationAnalyticsTopTenProducts){
        dispatch(getCCLocationAnalyticsTopProducts(payload_top_ten_products))
      }
    }
    else if(activeName === "subproduct") {
      dispatch(getCCLocationAnalyticsTopSubProducts(payload_top_ten_products))
    }
    else{
      dispatch(getCCLocationAnalyticsTopBases(payload_top_ten_products))
    }
  }, [activeName, topStatisticsLitreOrOrder])

  useEffect(() => {  
    if(ccLocationTotalSalesSuccess?.status === 'success'){
      let totalSalesVolumeXaxis_ary = []
      let totalSalesVolumeYaxis_ary = []
      ccLocationTotalSalesSuccess.data.result.forEach((data, key) => {
        totalSalesVolumeXaxis_ary.push(data?.date)
        if(salesVolumeLitreOrOrder === true){
          totalSalesVolumeYaxis_ary.push(data?.order)
        }
        else{
          totalSalesVolumeYaxis_ary.push(data?.volume)
        } 
      })
      setAnnualSalesVolumeDownload(ccLocationTotalSalesSuccess.data.result)
      setAnnualSalesVolumeXaxis(totalSalesVolumeXaxis_ary)
      setAnnualSalesVolumeYaxis(totalSalesVolumeYaxis_ary)
    }
    else{
      setAnnualSalesVolumeDownload([])
      setAnnualSalesVolumeXaxis([])
      setAnnualSalesVolumeYaxis([])
    }    
  }, [ccLocationTotalSalesSuccess])

  useEffect(() => {  
    if(ccLocationCustomFormulaSuccess?.status === 'success'){
      setCustomFormulaSales(ccLocationCustomFormulaSuccess.data.result)
    }    
  }, [ccLocationCustomFormulaSuccess])

  useEffect(() => {  
    if(ccLocationVolumeOrderSuccess?.status === 'success'){
      setVolumeOrders(ccLocationVolumeOrderSuccess.data.result)
    }    
  }, [ccLocationVolumeOrderSuccess])

  function ordinal_suffix_of(i) {
      var j = i % 10,
          k = i % 100;
      if (j === 1 && k !== 11) {
          return i + "st";
      }
      if (j === 2 && k !== 12) {
          return i + "nd";
      }
      if (j === 3 && k !== 13) {
          return i + "rd";
      }
      return i + "th";
  }

  useEffect(() => {  
    if(ccLocationInformationSuccess?.status === 'success'){
      setLocationAnalyticsInformation(ccLocationInformationSuccess.data.result)
      function pad2(n) {
        return (n < 10 ? '0' : '') + n;
      }
      var date = new Date(ccLocationInformationSuccess.data.result.dispensing_timestamp)
      var day = pad2(date.getDate());//day (1-31)
      var year= date.getFullYear();

      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      
      const timeString12hr = new Date(ccLocationInformationSuccess.data.result.dispensing_timestamp)
      .toLocaleTimeString([],
          {hour:'2-digit',minute:'2-digit'}
      );
      var formattedDate =  ordinal_suffix_of(day) + " " + monthNames[date.getMonth()] + " "+ year + " " + timeString12hr;
      setLocationAnalyticsSyncDate(formattedDate)

    }  
    else{
      setLocationAnalyticsInformation()
    }  
  }, [ccLocationInformationSuccess])

  useEffect(() => {  
    if(ccLocationTopTenColorsSuccess?.status === 'success'){
      let topTenColors_ary = []
      ccLocationTopTenColorsSuccess.data.result.forEach((data, key) => {
        topTenColors_ary.push(
          { value: (key+1)*10, name: data?.color_key_2+' : '+data?.sales_volume, itemStyle: {color: topTenColors[key]} }
        )
      })
      setTopTenColorDownload(ccLocationTopTenColorsSuccess.data.result)
      setTopTenColorsData(topTenColors_ary)
    }
    else{
      setTopTenColorDownload([])
      setTopTenColorsData([])
    }    
  }, [ccLocationTopTenColorsSuccess])

  useEffect(() => {  
    if(ccLocationTopProductsSuccess?.status === 'success' && activeName === "product"){
      let topTenProducts_ary = []
      let topTenProductsData_ary = []
      ccLocationTopProductsSuccess.data.result.slice().reverse().forEach((data, key) => {
        topTenProducts_ary.push(data?.product)
        if(topStatisticsLitreOrOrder === true){
          topTenProductsData_ary.push(data?.orders)
        }else{
          topTenProductsData_ary.push(data?.volume)
        }
      })
      setTopTenProductDownload(ccLocationTopProductsSuccess.data.result)
      setTopTenProductsYaxis(topTenProducts_ary)
      setTopTenProductsData(topTenProductsData_ary)
    }
    else{
      setTopTenProductsYaxis([])
      setTopTenProductsData([])
    }    
  }, [ccLocationTopProductsSuccess, activeName])

  useEffect(() => {  
    if(ccLocationTopSubProductsSuccess?.status === 'success' && activeName === "subproduct"){
      let topTenProducts_ary = []
      let topTenProductsData_ary = []
      ccLocationTopSubProductsSuccess.data.result.slice().reverse().forEach((data, key) => {
        topTenProducts_ary.push(data?.subproduct)
        if(topStatisticsLitreOrOrder === true){
          topTenProductsData_ary.push(data?.orders)
        }else{
          topTenProductsData_ary.push(data?.volume)
        }
      })
      setTopTenSubProductDownload(ccLocationTopSubProductsSuccess.data.result)
      setTopTenProductsYaxis(topTenProducts_ary)
      setTopTenProductsData(topTenProductsData_ary)
    }
    else{
      setTopTenSubProductDownload([])
      setTopTenProductsYaxis([])
      setTopTenProductsData([])
    }    
  }, [ccLocationTopSubProductsSuccess, activeName])

  useEffect(() => {  
    if(ccLocationTopBasesSuccess?.status === 'success' && activeName === "base"){
      let topTenProducts_ary = []
      let topTenProductsData_ary = []
      ccLocationTopBasesSuccess.data.result.slice().reverse().forEach((data, key) => {
        topTenProducts_ary.push(data?.base)
        if(topStatisticsLitreOrOrder === true){
          topTenProductsData_ary.push(data?.orders)
        }else{
          topTenProductsData_ary.push(data?.volume)
        }
      })
      setTopTenBaseDownload(ccLocationTopBasesSuccess.data.result)
      setTopTenProductsYaxis(topTenProducts_ary)
      setTopTenProductsData(topTenProductsData_ary)
    } 
    else{
      setTopTenBaseDownload([])
      setTopTenProductsYaxis([])
      setTopTenProductsData([])
    }   
  }, [ccLocationTopBasesSuccess, activeName])

  useEffect(() => {  
    if(ccLocationAvgLocationFootfallSuccess?.status === 'success'){
      let topCanSalesXaxis_ary = []
      let topCanSalesData_ary = []
      ccLocationAvgLocationFootfallSuccess.data.result.graph.forEach((data, key) => {
        var xvalue; 
        if(data?.hour < 10){
          xvalue = `0${data?.hour}:00 AM`;
        } 
        else{
          if(data?.hour < 12){
            xvalue = `${data?.hour}:00 AM`;
          }
          else{
            xvalue = '' + (data?.hour === 12 ? 12 + ":" + "00 PM" : data?.hour === 24 ? "12:" + "00 AM" : (data?.hour - 12) + ":" + "00 PM") ;
          }
          
        }
        topCanSalesXaxis_ary.push(xvalue)
        topCanSalesData_ary.push(data?.value)
      })
      setTopTopCanSalesXaxis(topCanSalesXaxis_ary)
      setTopCanSalesData(topCanSalesData_ary)
      setActivationDays(ccLocationAvgLocationFootfallSuccess.data.result.number_of_active_days)
    }    
  }, [ccLocationAvgLocationFootfallSuccess])

  useEffect(() => {  
    if(ccLocationMachineStatSuccess?.status === 'success'){
      setMachineStatData(ccLocationMachineStatSuccess.data.result)
      
    }    
  }, [ccLocationMachineStatSuccess])

  useEffect(() => {  
    if(ccLocationColorantConsumptionSuccess?.status === 'success'){  
      let colorantConsumptionXaxis_ary = []
      let series_object = []
      let colors = []
      let download_array = []
      ccLocationColorantConsumptionSuccess.data.result.forEach((data, key) => {
        colorantConsumptionXaxis_ary.push(data?.date)        
        if(data?.volume){
          colors = Object.keys(data.volume)          
        }
        download_array.push(data.volume)
      })      
      setColorantConsumptionXAxis(colorantConsumptionXaxis_ary)

      if(colors.length){
        colors.forEach((key_v, key_k) => {
          let series_array_data = []
          ccLocationColorantConsumptionSuccess.data.result.forEach((data_v, key_kk) => {            
            series_array_data.push(data_v.volume[''+key_v+''])
          })
          series_object.push({
            name: key_v,
            type: 'line',
            smooth: true,
            data: series_array_data
          })
        })        
      }
      download_array.forEach((i, k) => {
        ccLocationColorantConsumptionSuccess.data.result.forEach((data, key) => {
           if(key=== k){
            download_array[k].Date = data.date
           }
        })
      })
      setColorantConsumptionDownload(download_array)
      setColorantConsumptionSeries(series_object)
    }    
  }, [ccLocationColorantConsumptionSuccess])

    //Colorant Consumption Bar
    useEffect(() => {  
      if(ccLocationColorantConsumptionBarSuccess?.status === 'success'){
        let colorantConsumptionBarXaxis_ary = []
        let colorantConsumptionBarData_ary = []
        let colorantConsumptionBarColor_ary = []
        ccLocationColorantConsumptionBarSuccess.data.result.forEach((data, key) => {
          colorantConsumptionBarXaxis_ary.push(data?.colorant)
          colorantConsumptionBarData_ary.push(data?.colorant_qnt)
          colorantConsumptionBarColor_ary.push(data?.hex_color)
        })
        const filteredData = ccLocationColorantConsumptionBarSuccess.data.result.map(({ colorant, colorant_qnt }) => ({
          colorant,
          colorant_qnt
        }));
        setColorantConsumptionBarDownload(filteredData);
        setColorantConsumptionBarXAxis(colorantConsumptionBarXaxis_ary)
        setColorantConsumptionBarSeries(colorantConsumptionBarData_ary)
        setColorantConsumptionBarColor(colorantConsumptionBarColor_ary)
      }    
    }, [ccLocationColorantConsumptionBarSuccess])
 
  const handleRowClick = (item) => () => {
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8'
  const fileExtension = '.xlsx';
  
  const exportToExcel = async (excellData, filename) => {
    const ws = XLSX.utils.json_to_sheet(excellData);
    const wb = { Sheets: { 'data': ws}, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, filename + fileExtension);
  }

  let verticalLine = ["<span style='border-left: 1px solid #fff;margin-right: 5px;margin-left: 5px'>  </span>"];
  
  const locationAnalyticsnformationref = useRef();
  const locationAnalyticsnformationport = UseIntersection(locationAnalyticsnformationref, '0px');
  const [isLoadlocationAnalyticsnformation, setIsLoadlocationAnalyticsnformation] = useState(false);

  if (locationAnalyticsnformationport) {
    if(!isLoadlocationAnalyticsnformation){
      let payload_analytics_information = {
        dealer_code: location.state.dealer_code,
        pos_name: location.state.pos_name,
        pos_address: location.state.pos_address,
        pos_country: location.state.pos_country,
        bucket_id: location.state.bucket_id,
      }
      dispatch(getCCLocationAnalyticsInformation(payload_analytics_information))
      setIsLoadlocationAnalyticsnformation(true)
    }
  }

  const locationAnalyticTotalSalesref = useRef();
  const locationAnalyticsTotalSalesport = UseIntersection(locationAnalyticTotalSalesref, '0px');
  const [isLoadlocationAnalyticsTotalSales, setIsLoadlocationAnalyticsTotalSales] = useState(false);

  if (locationAnalyticsTotalSalesport) {
    if(!isLoadlocationAnalyticsTotalSales){
      let payload_analytics_total_sales = {
        dealer_code: location.state.dealer_code,
        pos_name: location.state.pos_name,
        pos_address: location.state.pos_address,
        pos_country: location.state.pos_country,
        bucket_id: location.state.bucket_id,
        type: salesVolumeLitreOrOrder === true ? "order": "litre",
        start_date: totalSalesVolumeStartDate?.formattedDate,
        end_date: totalSalesVolumeEndDate?.formattedDate
      }
      dispatch(getCCLocationAnalyticTotalSales(payload_analytics_total_sales))
      setIsLoadlocationAnalyticsTotalSales(true)
    }
  }

  useEffect(() => {
    let payload_analytics_total_sales = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: salesVolumeLitreOrOrder === true ? "order": "litre",
      start_date: totalSalesVolumeStartDate?.formattedDate,
      end_date: totalSalesVolumeEndDate?.formattedDate
    }
    dispatch(getCCLocationAnalyticTotalSales(payload_analytics_total_sales))
  }, [salesVolumeLitreOrOrder])

  const locationAnalyticVolumesOrderref = useRef();
  const locationAnalyticsVolumesOrderport = UseIntersection(locationAnalyticVolumesOrderref, '0px');
  const [isLoadlocationAnalyticsVolumesOrder, setIsLoadlocationAnalyticsVolumesOrder] = useState(false);

  if (locationAnalyticsVolumesOrderport) {
    if(!isLoadlocationAnalyticsVolumesOrder){
      let payload_analytics_information = {
        dealer_code: location.state.dealer_code,
        pos_name: location.state.pos_name,
        pos_address: location.state.pos_address,
        pos_country: location.state.pos_country,
        bucket_id: location.state.bucket_id
      }
      dispatch(getCCLocationAnalyticsVolumeOrder(payload_analytics_information))
      setIsLoadlocationAnalyticsVolumesOrder(true)
    }
  }

  const locationAnalyticCustomFormularef = useRef();
  const locationAnalyticsCustomFormulaport = UseIntersection(locationAnalyticCustomFormularef, '0px');
  const [isLoadlocationAnalyticsCustomFormula, setIsLoadlocationAnalyticsCustomFomula] = useState(false);

  if (locationAnalyticsCustomFormulaport) {
    if(!isLoadlocationAnalyticsCustomFormula){
      let payload_analytics_information = {
        dealer_code: location.state.dealer_code,
        pos_name: location.state.pos_name,
        pos_address: location.state.pos_address,
        pos_country: location.state.pos_country,
        bucket_id: location.state.bucket_id
      }
      dispatch(getCCLocationAnalyticCustomFormula(payload_analytics_information))
      setIsLoadlocationAnalyticsCustomFomula(true)
    }
  }

  const locationAnalyticAvgLocationFootfallref = useRef();
  const locationAnalyticsAvgLocationFootfallport = UseIntersection(locationAnalyticAvgLocationFootfallref, '0px');
  const [isLoadlocationAnalyticsAvgLocationFootfall, setIsLoadlocationAnalyticsAvgLocationFootfall] = useState(false);

  if (locationAnalyticsAvgLocationFootfallport) {
    if(!isLoadlocationAnalyticsAvgLocationFootfall){
      let payload_analytics_information = {
        dealer_code: location.state.dealer_code,
        pos_name: location.state.pos_name,
        pos_address: location.state.pos_address,
        pos_country: location.state.pos_country,
        bucket_id: location.state.bucket_id
      }
      dispatch(getCCLocationAnalyticsAvgLocationFootfall(payload_analytics_information))
      setIsLoadlocationAnalyticsAvgLocationFootfall(true)
    }
  }


  //Colorant Consumption Bar
  const locationAnalyticsColorantConsumptionBarref = useRef();
  const locationAnalyticsColorantConsumptionBarport = UseIntersection(locationAnalyticsColorantConsumptionBarref, '0px');
  const [isLoadlocationAnalyticsColorantConsumptionBar, setIsLoadlocationAnalyticColorantConsumptionBar] = useState(false);

  if (locationAnalyticsColorantConsumptionBarport) {
    if(!isLoadlocationAnalyticsColorantConsumptionBar){
      let payload_colorant_consumption_Bar = {
        dealer_code: location.state.dealer_code,
        pos_name: location.state.pos_name,
        pos_address: location.state.pos_address,
        pos_country: location.state.pos_country,
        bucket_id: location.state.bucket_id,
        type: topStatisticsLitreOrOrder === true ? "order": "litre",
        start_date: colorantConsumptionBarStartDate?.formattedDate,
        end_date: colorantConsumptionBarEndDate?.formattedDate
      }
      dispatch(getCCLocationAnalyticsColorantConsumptionBar(payload_colorant_consumption_Bar))
      setIsLoadlocationAnalyticColorantConsumptionBar(true)
    }
  }

  const locationAnalyticsMachineStatref = useRef();
  const locationAnalyticsMachineStatport = UseIntersection(locationAnalyticsMachineStatref, '0px');
  const [isLoadlocationAnalyticsMachineStat, setIsLoadlocationAnalyticsMachineStat] = useState(false);

  if (locationAnalyticsMachineStatport) {
    if(!isLoadlocationAnalyticsMachineStat){
      let payload_analytics_information = {
        dealer_code: location.state.dealer_code,
        pos_name: location.state.pos_name,
        pos_address: location.state.pos_address,
        pos_country: location.state.pos_country,
        bucket_id: location.state.bucket_id
      }
      dispatch(getCCLocationAnalyticsMachineStat(payload_analytics_information))
      setIsLoadlocationAnalyticsMachineStat(true)
    }
  }

  const locationAnalyticsColorantConsumptionref = useRef();
  const locationAnalyticsColorantConsumptionport = UseIntersection(locationAnalyticsColorantConsumptionref, '0px');
  const [isLoadlocationAnalyticsColorantConsumption, setIsLoadlocationAnalyticColorantConsumption] = useState(false);

  if (locationAnalyticsColorantConsumptionport) {
    if(!isLoadlocationAnalyticsColorantConsumption){
      let payload_colorant_consumption = {
        dealer_code: location.state.dealer_code,
        pos_name: location.state.pos_name,
        pos_address: location.state.pos_address,
        pos_country: location.state.pos_country,
        bucket_id: location.state.bucket_id,
        type: topStatisticsLitreOrOrder === true ? "order": "litre",
        start_date: colorantConsumptionStartDate?.formattedDate,
        end_date: colorantConsumptionEndDate?.formattedDate
      }
      dispatch(getCCLocationAnalyticsColorantConsumption(payload_colorant_consumption))
      setIsLoadlocationAnalyticColorantConsumption(true)
    }
  }

  const locationAnalyticsTopTenProducts = useRef();
  const locationAnalyticsTopTenProductsport = UseIntersection(locationAnalyticsTopTenProducts, '0px');
  const [isLoadlocationAnalyticsTopTenProducts, setIsLoadlocationAnalyticTopTenProducts] = useState(false);

  if (locationAnalyticsTopTenProductsport) {
    if(!isLoadlocationAnalyticsTopTenProducts){
      let payload_total_sales_statistics = {
        dealer_code: location.state.dealer_code,
        pos_name: location.state.pos_name,
        pos_address: location.state.pos_address,
        pos_country: location.state.pos_country,
        bucket_id: location.state.bucket_id,
        type: topStatisticsLitreOrOrder === true ? "order": "litre",
        start_date: totalSalesStatisticsStartDate?.formattedDate,
        end_date: totalSalesStatisticsEndDate?.formattedDate
      }
      dispatch(getCCLocationAnalyticsTopProducts(payload_total_sales_statistics))
      setIsLoadlocationAnalyticTopTenProducts(true)
    }
  }


  const locationAnalyticsTopTenColors = useRef();
  const locationAnalyticsTopTenColorsport = UseIntersection(locationAnalyticsTopTenColors, '0px');
  const [isLoadlocationAnalyticsTopTenColors, setIsLoadlocationAnalyticTopTenColors] = useState(false);

  if (locationAnalyticsTopTenColorsport) {
    if(!isLoadlocationAnalyticsTopTenColors){
      let payload_total_sales_statistics = {
        dealer_code: location.state.dealer_code,
        pos_name: location.state.pos_name,
        pos_address: location.state.pos_address,
        pos_country: location.state.pos_country,
        bucket_id: location.state.bucket_id,
        type: topStatisticsLitreOrOrder === true ? "order": "litre",
        start_date: totalSalesStatisticsStartDate?.formattedDate,
        end_date: totalSalesStatisticsEndDate?.formattedDate
      }
      dispatch(getCCLocationAnalyticsTopTenColors(payload_total_sales_statistics))
      setIsLoadlocationAnalyticTopTenColors(true)
    }
  }

  useEffect(() => {
    let payload_total_sales_statistics = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      start_date: totalSalesStatisticsStartDate?.formattedDate,
      end_date: totalSalesStatisticsEndDate?.formattedDate
    }
    if(isLoadlocationAnalyticsTopTenColors){
      dispatch(getCCLocationAnalyticsTopTenColors(payload_total_sales_statistics))
    }
  }, [topStatisticsLitreOrOrder])



  // const machineStatus = useRef();
  // const machineStatusport = UseIntersection(machineStatus, '0px');
  // const [isLoadMachineStatus, setIsLoadMachineStatus] = useState(false);

  // if (machineStatusport) {
  //   if(!isLoadMachineStatus){
  //     let payload_total_sales_statistics = {
  //       pos_name: "D20C1229",
  //       pos_address: "JAY BHAWANI ROAD OPP VASANT BAJAJ NASHIKROAD NASHIK",
  //       pos_country: "IND",
  //       bucket_id: "esdop9",
  //     }
  //     dispatch(getCCLocationAnalyticsMachineStat(payload_total_sales_statistics))
  //     setIsLoadMachineStatus(true)
  //   }
  // }

  const locationInformationTryClick = () => {
    let payload_analytics_information = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
    }
    dispatch(getCCLocationAnalyticsInformation(payload_analytics_information))
  }

  const colorantConsumptionTryClick = () => {
    let payload_colorant_consumption = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      start_date: colorantConsumptionStartDate?.formattedDate,
      end_date: colorantConsumptionEndDate?.formattedDate
    }
    dispatch(getCCLocationAnalyticsColorantConsumption(payload_colorant_consumption))
  }

  //Colorant Consumption Bar
  const colorantConsumptionBarTryClick = () => {
    let payload_colorant_consumption_Bar = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      start_date: colorantConsumptionBarStartDate?.formattedDate,
      end_date: colorantConsumptionBarEndDate?.formattedDate
    }
    dispatch(getCCLocationAnalyticsColorantConsumptionBar(payload_colorant_consumption_Bar))
  }

  const avgLocationTryClick = () => {
    let payload_analytics_information = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id
    }
    dispatch(getCCLocationAnalyticsAvgLocationFootfall(payload_analytics_information))
  }

  const customFormulaTryClick = () => {
    let payload_analytics_information = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id
    }
    dispatch(getCCLocationAnalyticCustomFormula(payload_analytics_information))
  }

  const totalSalesVolumeTryClick = () => {
    let payload_analytics_total_sales = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: salesVolumeLitreOrOrder === true ? "order": "litre",
      start_date: totalSalesVolumeStartDate?.formattedDate,
      end_date: totalSalesVolumeEndDate?.formattedDate
    }
    dispatch(getCCLocationAnalyticTotalSales(payload_analytics_total_sales))
  }

  const topTenColorsTryClick = () => {
    let payload_total_sales_statistics = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      start_date: totalSalesStatisticsStartDate?.formattedDate,
      end_date: totalSalesStatisticsEndDate?.formattedDate
    }
    dispatch(getCCLocationAnalyticsTopTenColors(payload_total_sales_statistics))
  }

  const locationProductTryClick =() => {
    let payload_total_sales_statistics = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      start_date: totalSalesStatisticsStartDate?.formattedDate,
      end_date: totalSalesStatisticsEndDate?.formattedDate
    }
    dispatch(getCCLocationAnalyticsTopProducts(payload_total_sales_statistics))
  }

  const locationSubProductTryClick =() =>{
    let payload_top_ten_products = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      start_date: totalSalesStatisticsStartDate?.formattedDate,
      end_date: totalSalesStatisticsEndDate?.formattedDate
    }
    dispatch(getCCLocationAnalyticsTopSubProducts(payload_top_ten_products))
  }

  const locationBaseTryClick = () => {
    let payload_top_ten_products = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id,
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      start_date: totalSalesStatisticsStartDate?.formattedDate,
      end_date: totalSalesStatisticsEndDate?.formattedDate
    }
    dispatch(getCCLocationAnalyticsTopBases(payload_top_ten_products))
  }

  const locationVolumeOrderTryClick = () => {
    let payload_analytics_information = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id
    }
    dispatch(getCCLocationAnalyticsVolumeOrder(payload_analytics_information))
  }

  const errorBlockBtnClick = () => {
    let payload_analytics_information = {
      dealer_code: location.state.dealer_code,
      pos_name: location.state.pos_name,
      pos_address: location.state.pos_address,
      pos_country: location.state.pos_country,
      bucket_id: location.state.bucket_id
    }
    dispatch(getCCLocationAnalyticsMachineStat(payload_analytics_information))
  }

  

  return (
    <DashboardLayout >

      <BreadCrumb linkData={linkData}/>

      <FirstSection>

      <div className={classes.row}>
      <div className={classes.col30}>
      <div className={classes.cardDiv}>
            <DashboardCard
            adjust
            adjustText
            errorStatus = {ccLocationInformationError}
            tryClick={locationInformationTryClick}
            errorTopheight={"translateY(80%)"}
            observerref={locationAnalyticsnformationref}
                loading={ccLocationInformationLoading}
                title={t('xdataLocationDetails.locationInformation')}
                height={230}
                icon="document"
                columnInfo3={true}
                columnInfo3Data= { 
                  {
                    column1Title: t('xdataLocationDetails.posname'),
                    column1Desc: locationAnalyticsInformation?.pos_name,
                    column2Title: t('xdataLocationDetails.address'),
                    column2Desc: locationAnalyticsInformation?.pos_address,
                    column3Title:t('xdataLocationDetails.dealer'),
                    column3Desc: locationAnalyticsInformation?.dealer_code,
                  }
                }
                
            />
            <DashboardCard
            adjust
            errorStatus = {ccLocationVolumeOrderError}
            tryClick={locationVolumeOrderTryClick}
            errorTopheight={"translateY(80%)"}
            observerref={locationAnalyticVolumesOrderref}
            loading={ccLocationVolumeOrderLoading}
                title={t('xdataLocationDetails.volumeTinted')}
                height={200}
                icon="fill"
                columnInfo2={true}
                columnInfo2Data= { 
                  {
                    column1Title: t('xdataLocationDetails.volume'),
                    column1Desc: volumeOrders ? volumeOrders?.volume_tinted +  'L' : null ,
                    column2Title: t('xdataLocationDetails.NoOfOrders'),
                    column2Desc: volumeOrders?.orders,
                  }
                }
                footerLeftContent = {
                  {
                    desc: t('xdataLocationDetails.last24Hour')
                  }
                }
                footerRightContent = {
                  {
                    title: t('xdataLocationDetails.dataLastSynced'),
                    desc: locationAnalyticsSyncDate
                  }
                }
            />
          </div>
        </div>
        <div className={classes.col60} ref={locationAnalyticTotalSalesref}>
            <div className={classes.chartHeader}>
              <h2 className={classes.chartTitle}>{t('xdataLocationDetails.totalSalesVolume')}</h2>
              <div className={classes.chartRightDiv1}>
              <div className={classes.chartRightDiv2}>               
                
                <ChartButton onClick={toggleTotalSalesVolume}>
                  <CalendarIcon className={classes.CalendarIcon}/> {totalSalesVolumeStartDate.formattedStandardDate} - {totalSalesVolumeEndDate.formattedStandardDate}
                </ChartButton>    
                <Tooltip title={t('xdataLocationDetails.download')}> 
                <ChartButton clickDisable={annualSalesVolumeXaxis.length === 0 ? true: false} onClick={() => exportToExcel(annualSalesVolumeDownload, "Total Sales Volume")}>
                  <DownloadIcon className={classes.DownloadIcon}/>
                </ChartButton>
                </Tooltip>
                
              </div> 
              <div style={{marginTop: 25, textAlign: 'right'}}>
                <span className={classes.chartRightChild}>
                  <div className='radioButtonStyle' style={{display: 'inline-grid'}}>
                      <FormControlLabel  name="radio-buttons" value={true}  control={<Radio checked={salesVolumeLitreOrOrder === true} onChange={(event) => setSalesVolumeLiterOrOrder(true)}  style ={{color: "#C8102E"}} />} 
                            label={t('XdataAnalyticsDetail.OrdersTinted')}/>
                        <FormControlLabel  name="radio-buttons" value={false}  control={<Radio checked={salesVolumeLitreOrOrder === false} onChange={(event) => setSalesVolumeLiterOrOrder(false)}  style ={{color: "#C8102E"}} />} 
                        label={t('XdataAnalyticsDetail.LitersSold')}/>
                  </div>
                </span>
                </div> 
              </div> 
          </div>
          <div className='calendarStyle'> 
              <DateRangePicker
                maxDate={new Date(new Date().setDate(new Date().getDate()))}
                minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                definedRanges={["Last Month", "This Year"]}
                open={openTotalSalesVolume}
                toggle={toggleTotalSalesVolume}
                onChange={(range) => setDateRangeTotalSalesVolume(range)}
                wrapperClassName={classes.wrapperClassNameSalesVolume}
            />
          </div>
        {
          ccLocationTotalSalesLoading ? 
          <div className={classes.loaderDiv}>
                    <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                </div>:
                ccLocationTotalSalesError === 0 ? 
                <ErrorBlock btnClick={totalSalesVolumeTryClick} />
                  :
          annualSalesVolumeXaxis.length === 0 ? 
          <div className={classes.loaderDiv}>
            <p className={classes.nodataText}>{t('xdataLocationDetails.noDataFound')}</p>
          </div>
          :
          <>
          <AreaChart heightValue={350} chartInfo={
          {
            tooltip: {
              trigger: 'axis',
              position: function (pt) {
                return [pt[0], '10%'];
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '15%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: annualSalesVolumeXaxis,
              axisLabel: {
                showMaxLabel: true
              }             
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                // formatter: '{value} L',
                formatter: '{value}',
                margin: 10,
              }
            },
            textStyle: {
              fontFamily: 'Roboto',
           },
            series: [
              {
                data: annualSalesVolumeYaxis,
                type: 'line',
                smooth: true,
                itemStyle: {color: '#C8102E'},
                lineStyle: {color: '#C8102E'},
                areaStyle: {color: '#FFB1A8'},
              }
            ]
          }
        }/>
        {
            salesVolumeLitreOrOrder === false ? 
              <div style={{display: 'flex', marginTop: -40}}>
                <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
            </div>:
            null
          }
          </>
        }
        </div>
        
      </div>
      </FirstSection>

      <DoubleWhiteCard>
        <div className={classes.datechartHeader}>
            <h2 className={classes.chartTitle}>{t('xdataLocationDetails.topSaleStatistics')}</h2>
            <div className={classes.chartRightDiv} style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
              <span className={classes.chartRightChild}>
                <div className='radioButtonStyle' style={{display: 'inline-grid'}}>
                      <FormControlLabel  name="radio-buttons" value={true}  control={<Radio checked={topStatisticsLitreOrOrder === true} onChange={(event) => setTopStatisticsLitreOrder(true)}  style ={{color: "#C8102E"}} />} 
                            label={t('XdataAnalyticsDetail.OrdersTinted')}/>
                        <FormControlLabel style={{marginTop: 3}}  name="radio-buttons" value={false}  control={<Radio checked={topStatisticsLitreOrOrder === false} onChange={(event) => setTopStatisticsLitreOrder(false)}  style ={{color: "#C8102E"}} />} 
                        label={t('XdataAnalyticsDetail.LitersSold')}/>
                  </div>
              </span>
              <ChartButton  onClick={toggleTotalSalesStatistics} style={{height: 32,paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10}}>
                <CalendarIcon className={classes.CalendarIcon}/> {totalSalesStatisticsStartDate.formattedStandardDate} - {totalSalesStatisticsEndDate.formattedStandardDate}
              </ChartButton>                
            </div>            
        </div>
        <DateRangePicker
          maxDate={new Date(new Date().setDate(new Date().getDate()))}
          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
          definedRanges={["Last Month", "This Year"]}
          open={openTotalSalesStatistics}
          toggle={toggleTotalSalesStatistics}
          onChange={(range) => setDateRangeTotalSalesStatistics(range)}
          wrapperClassName={classes.wrapperClassNameSalesVolume}
        />
        <div className={classes.DoubleWhiteCardChild} ref={locationAnalyticsTopTenColors}>
          <div className={classes.chartHeader}>
              <h2 className={classes.chartTitle}>{t('xdataLocationDetails.colors')}</h2>
              <div className={classes.chartRightDiv}>
              <Tooltip title={t('xdataLocationDetails.download')}> 
                <ChartButton clickDisable={topTenColorsData.length === 0 ? true: false} onClick={() => exportToExcel(topTenColorDownload, "Top Ten Color")}>
                  <DownloadIcon className={classes.DownloadIcon}/>
                </ChartButton>
                </Tooltip>
              </div>            
          </div>
          {
            ccLocationTopTenColorsLoading ? 
            <div className={classes.loaderDiv}>
                      <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                  </div>:
              ccLocationTopTenColorsError === 0 ?
              <ErrorBlock btnClick={topTenColorsTryClick} />
                :
              topTenColorsData.length === 0 ?
              <div className={classes.loaderDiv}>
                <p className={classes.nodataText}>{t('xdataLocationDetails.noDataFound')}</p>
              </div>
              :
          <FunnelChart chartInfo={{
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}'
            },
            legend: {
              right: -30,
              orient: 'vertical',
              padding: 50,
              itemGap: 10,
            },
            textStyle: {
              fontFamily: 'Roboto',
           },
            series: [
              {
                left: '0%',
                name: 'Color',
                type: 'funnel',
                sort: 'ascending',
                width: '50%',
                gap: 4,
                label: {
                  show: true,
                  color: 'black'
                },
                labelLine: {
                  length: 30,
                  lineStyle: {
                    width: 1,
                    type: 'solid'
                  }
                },
                itemStyle: {
                  borderColor: '#fff',
                  borderWidth: 1
                },
                emphasis: {
                  label: {
                    fontSize: 20
                  }
                },
                data: topTenColorsData
              }
            ]
          }}/>
        }
        </div>
        <div className={classes.DoubleWhiteCardChild} ref={locationAnalyticsTopTenProducts}>
          <div className={classes.chartHeader}>
              <div className={classes.chartLeftBtns}>
              {
                activeName === "product" ? <ChartButtonActive onClick={() => setActiveName("product")}>
                  {t('xdataLocationDetails.product')}
                </ChartButtonActive>: 
                <ChartButtonWithoutBg  onClick={() => setActiveName("product")}>
                  {t('xdataLocationDetails.product')}
                </ChartButtonWithoutBg>
              } 

              {
                activeName === "subproduct" ? <ChartButtonActive onClick={() => setActiveName("subproduct")}>
                  {t('xdataLocationDetails.subProduct')}
                </ChartButtonActive>: 
                <ChartButtonWithoutBg onClick={() => setActiveName("subproduct")}>
                  {t('xdataLocationDetails.subProduct')}
                </ChartButtonWithoutBg>
              }   
              {
                activeName === "base" ? <ChartButtonActive onClick={() => setActiveName("base")}>
                  {t('xdataLocationDetails.base')}
                </ChartButtonActive>: 
                <ChartButtonWithoutBg onClick={() => setActiveName("base")}>
                  {t('xdataLocationDetails.base')}
                </ChartButtonWithoutBg>
              }                                             
              </div>
              <div className={classes.chartRightDiv}>
              <Tooltip title={t('xdataLocationDetails.download')}> 
                <ChartButton clickDisable={topTenProductsYaxis.length === 0 ? true: false} onClick={() =>{activeName === "product" ? exportToExcel(topTenProductDownload, "Top Ten Products"): activeName === "subproduct" ? exportToExcel(topTenSubProductDownload, "Top Ten SubProducts"): exportToExcel(topTenBaseDownload, "Top Ten Base")}}>
                  <DownloadIcon className={classes.DownloadIcon}/>
                </ChartButton>
                </Tooltip>
              </div>            
          </div>
          {
            ccLocationTopProductsLoading ? 
            <div className={classes.loaderDiv}>
                      <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                  </div>:
            ccLocationTopSubProductsLoading ? 
            <div className={classes.loaderDiv}>
                      <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                  </div>:
            ccLocationTopBasesLoading ? 
            <div className={classes.loaderDiv}>
                <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
            </div>:
            ccLocationTopProductsError === 0 ? 
            <ErrorBlock btnClick={locationProductTryClick} />
              :
              ccLocationTopBasesError === 0 ? 
            <ErrorBlock btnClick={locationBaseTryClick} />
              :
              ccLocationTopSubProductsError === 0 ? 
            <ErrorBlock btnClick={locationSubProductTryClick} />
              :
          topTenProductsYaxis.length === 0 ? 
          <div className={classes.loaderDiv}>
            <p className={classes.nodataText}>{t('xdataLocationDetails.noDataFound')}</p>
          </div>
          :
          <BarChart chartInfo={{
            tooltip: {
              trigger: 'axis',
              // position: function (pt) {
              //   return [pt[0], '10%'];
              // },
              extraCssText: "background: #36333D;color: #FFFFFF; border-radius: 5px; font-size: 12px",
              formatter: function(d) {
                return d[0].name  + verticalLine + d[0].data;
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '5%',
              containLabel: true
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: topTenProductsYaxis
            },
            textStyle: {
              fontFamily: 'Roboto',
           },
            series: [
              {
                data: topTenProductsData,
                type: 'bar',
                itemStyle: {color: '#FF8397'},
                barWidth:25
              }
            ]
          }} />
        }
        </div>
        {
            topStatisticsLitreOrOrder === false ? 
              <div style={{display: 'flex'}}>
                <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
            </div>:
            null
          }
      </DoubleWhiteCard>

      <WhiteCard ref={locationAnalyticsColorantConsumptionref}>
        <div className={classes.datechartHeader}>
            <h2 className={classes.chartTitle}>{t('xdataLocationDetails.colorantConsumption')}</h2>
            <div className={classes.chartRightDiv}>
              <ChartButton  onClick={toggleColorantConsumption}>
                <CalendarIcon className={classes.CalendarIcon}/> {colorantConsumptionStartDate.formattedStandardDate} - {colorantConsumptionEndDate.formattedStandardDate}
              </ChartButton>
              <Tooltip title={t('xdataLocationDetails.download')}> 
              <ChartButton clickDisable={colorantConsumptionXAxis.length === 0 ? true: false} onClick={() => exportToExcel(colorantConsumptionDownload, "Colorant Consumption")}>
                <DownloadIcon className={classes.DownloadIcon}/>
              </ChartButton>
              </Tooltip>
            </div>            
        </div>
        <DateRangePicker
          maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
          definedRanges={["Last Month", "This Year"]}
          open={openColorantConsumption}
          toggle={toggleColorantConsumption}
          onChange={(range) => setDateRangeColorantConsumption(range)}
          wrapperClassName={classes.wrapperClassNameSalesVolume}
        />
        {
          ccLocationColorantConsumptionLoading ? 
          <div className={classes.loaderDiv}>
                    <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                </div>:
          ccLocationColorantConsumptionError === 0 ? 
          <ErrorBlock btnClick={colorantConsumptionTryClick} />
            :
          colorantConsumptionXAxis.length === 0 ? 
          <div className={classes.loaderDiv}>
            <p className={classes.nodataText}>{t('xdataLocationDetails.noDataFound')}</p>
          </div>
          :
        <AreaChart chartInfo={
          {
            tooltip: {
              trigger: 'axis',
              position: function (pt) {
                return [pt[0], '10%'];
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '15%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: colorantConsumptionXAxis,
              axisLabel: {
                showMaxLabel: true
              }              
            },
            yAxis: {
              type: 'value',
              min: 0,
              axisLabel: {
                // formatter: '{value} L',
                formatter: '{value}',
                margin: 10,
              }
            },
            dataZoom: [
              {
                type: 'inside',
                start: 0,
                end: 100
              },
              {
                start: 0,
                end: 100,
                borderColor: "#FFB1A8",
                backgroundColor: "#FFB1A8",
                height: 30,
                moveHandleStyle:{
                  color: "#C8102E",
                  opacity: 1
                },
                dataBackground:{
                    lineStyle: {
                      color: '#C8102E',
                      width: 0.5
                    },
                    areaStyle: {
                      color: '#C8102E',
                      width: 0.5
                    }
                },
                selectedDataBackground:{
                  lineStyle: {
                    color: '#C8102E',
                    width: 0.5
                  },
                  areaStyle: {
                    color: '#C8102E',
                    width: 0.5
                  }
              },
                emphasis:{
                  moveHandleStyle: {
                    color: "#C8102E",
                    opacity: 0.5
                  }
                },
                textStyle: {
                  color: '#000'
                }
              }
            ],
            textStyle: {
              fontFamily: 'Roboto',
           },
            series: colorantConsumptionSeries
          }
        }/>
      }
      </WhiteCard>

      <WhiteCard ref={locationAnalyticsColorantConsumptionBarref}>
        <div className={classes.datechartHeader}>
            <h2 className={classes.chartTitle}>{t('xdataLocationDetails.ColorantConsumptionBarChart')}</h2>
            <div className={classes.chartRightDiv}>
              <ChartButton  onClick={toggleColorantConsumptionBar}>
                <CalendarIcon className={classes.CalendarIcon}/> {colorantConsumptionBarStartDate.formattedStandardDate} - {colorantConsumptionBarEndDate.formattedStandardDate}
              </ChartButton>
              <Tooltip title={t('xdataLocationDetails.download')}> 
              <ChartButton clickDisable={colorantConsumptionBarXAxis.length === 0 ? true: false} onClick={() => exportToExcel(colorantConsumptionBarDownload, "Colorant Consumption")}>
                <DownloadIcon className={classes.DownloadIcon}/>
              </ChartButton>
              </Tooltip>
            </div>            
        </div>
        <DateRangePicker
          maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
          definedRanges={["Last Month", "This Year"]}
          open={openColorantConsumptionBar}
          toggle={toggleColorantConsumptionBar}
          onChange={(range) => setDateRangeColorantConsumptionBar(range)}
          wrapperClassName={classes.wrapperClassNameSalesVolume}
        />
        {
          ccLocationColorantConsumptionBarLoading ? 
          <div className={classes.loaderDiv}>
                    <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                </div>:
          ccLocationColorantConsumptionBarError === 0 ? 
          <ErrorBlock btnClick={colorantConsumptionBarTryClick} />
            :
          colorantConsumptionBarXAxis.length === 0 ? 
          <div className={classes.loaderDiv}>
            <p className={classes.nodataText}>{t('xdataLocationDetails.noDataFound')}</p>
          </div>
          :
          <>
        <BarChart chartInfo={{
                          tooltip: {
                            trigger: 'axis',
                            position: function (pt) {
                              return [pt[0], '10%'];
                            }
                          },
                          grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '6%',
                            containLabel: true
                          },
                          xAxis: {
                            type: 'category',
                            data: colorantConsumptionBarXAxis,
                            axisLabel: {
                              interval: 0,
                              rotate: 30 //If the label names are too long you can manage this by rotating the label.
                            },
                            axisTick: {
                              alignWithLabel: true
                            }
                          },
                          yAxis: {
                            type: 'value',
                          },
                          textStyle: {
                            fontFamily: 'Roboto',
                        },
                          series: [                
                              {                  
                                data: colorantConsumptionBarSeries,
                                type: 'bar',
                                itemStyle: {borderRadius: 5,
                                  color: function (params) {
                                  const color = colorantConsumptionBarColor[params.dataIndex % colorantConsumptionBarColor.length];
                                  return color;
                                },
                                barWidth:13
                              }
                            }
                            ]
                          
                      }}/>
                      <div style={{display: 'flex', marginLeft: 40, marginTop: 10}}>
                                      <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                                      <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
                                  </div>
                                  </>
      }
      </WhiteCard>

      <div className={classes.row}>
        <div className={classes.colRight601} ref={locationAnalyticAvgLocationFootfallref} style={{position: 'relative'}}>
            <div className={classes.datechartHeader}>
              <h2 className={classes.chartTitle}>{t('xdataLocationDetails.avgLocationFootFall')}</h2>
              <div className={classes.datechartRightDiv}>
              <Tooltip title={t('xdataLocationDetails.reload')}> 
                <Refresh onClick={avgLocationTryClick} style={{float: 'right', padding: 7}}>
                  <AiOutlineReload className={classes.DownloadIcon}/>
                </Refresh>
                </Tooltip>
                {
                  topTopCanSalesXaxis.length !== 0 ? 
                  <>
                  <span className={classes.iconDiv}>
                  <ShopIcon className={classes.icon}/>
                  
                </span>
                <div style={{marginLeft: 10}}>
                  <p style={{fontSize: 15, fontFamily: 'Roboto', fontWeight: 'bold'}}>{activationDays} {t('xdataLocationDetails.days')}</p>
                  <p style={{fontSize: 10, fontFamily: 'Roboto'}}>{t('xdataLocationDetails.numberOfActiveDays')}</p>
                </div>
                  </>:null
                }
              </div>            
          </div>
          {
          ccLocationAvgLocationFootfallLoading ? 
          <div className={classes.loaderDiv}>
                    <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                </div>:
                ccLocationAvgLocationFootfallError === 0 ? 
                <ErrorBlock btnClick={avgLocationTryClick} />
                  :
          topTopCanSalesXaxis.length === 0 ? 
          <>
          <div className={classes.loaderDiv}>
            <p className={classes.nodataText}>{t('xdataLocationDetails.noDataFound')}</p>
          </div>
          <div style={{position: 'absolute', bottom: 20, left: 40, display: 'flex'}}>
              <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
              <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('xdataLocationDetails.last365Days')}</p>
            </div>
          </>
          :
          <>
          <BarChart chartInfo={{
              tooltip: {
                trigger: 'axis',
                position: function (pt) {
                  return [pt[0], '10%'];
                }
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '6%',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                data: topTopCanSalesXaxis,
              },
              yAxis: {
                type: 'value'
              },
              textStyle: {
                fontFamily: 'Roboto',
             },
              series: [                
                {                  
                  data: topCanSalesData,
                  type: 'bar',
                  itemStyle: {color: '#C8102E'},
                  barWidth:13
                }
              ]
          }}/>
          <div style={{display: 'flex', marginLeft: 20}}>
            <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
            <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('xdataLocationDetails.last365Days')}</p>
          </div>
          </>
        }
        </div>
        <div className={classes.colLeft301} ref={locationAnalyticCustomFormularef} style={{position: 'relative'}}>
            <div className={classes.datechartHeader}>
                <h2 className={classes.chartTitleFullWidth}>{t('xdataLocationDetails.salesOfCustomFormulae')}</h2>      
                <Tooltip title={t('xdataLocationDetails.reload')}> 
              <ChartButton  onClick={customFormulaTryClick} style={{padding: 7}}>
                <AiOutlineReload className={classes.DownloadIcon}/>
              </ChartButton> 
              </Tooltip>                  
            </div>
            {
            ccLocationCustomFormulaLoading ? 
                <div className={classes.loaderDiv}>
                    <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                </div>:
                ccLocationCustomFormulaError === 0 ? 
                <ErrorBlock btnClick={customFormulaTryClick} />
                  :
                customFormulaSales?.total === 0 ?
                <>
                <div className={classes.loaderDiv}>
                <p className={classes.nodataText}>{t('xdataLocationDetails.noDataFound')}</p>
              </div>
              <div style={{position: 'absolute', display: 'flex', bottom: 20}}>
                <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('xdataLocationDetails.last365Days')}</p>
              </div>
              </>
              :
                <div style={{marginTop: 36}}>
            <PieChart chartInfo={{
                  tooltip: {
                    trigger: 'item'
                  },
                  title: {
                    text: t('xdataLocationDetails.total') + customFormulaSales?.total,
                    left: 'center',
                    top: 'center',
                 },
                  legend: {
                    bottom: 0,
                    right: 0,
                    align: 'left'
                  },
                  textStyle: {
                    fontFamily: 'Roboto',
                 },
                  series: [
                      {
                          name: "",
                          type: 'pie',
                          radius: ['45%', '70%'],
                          avoidLabelOverlap: false,
                          label: {
                          show: false,
                          position: 'center'
                          },
                          emphasis: {
                          label: {
                              show: false,
                              fontSize: '18',
                              fontWeight: 'bold',
                              // formatter: () => {
                              //   return "Total:" + customFormulaSales?.total; // Use sum variable here
                              // },
                          }
                          },
                          labelLine: {
                          show: false
                          },
                          data: [
                              { 
                                  value: customFormulaSales?.regular, 
                                  name: t('xdataLocationDetails.regular'), 
                                  itemStyle: { color: '#C8102E' },
                                  selected: true
                              },
                              { 
                                  value: customFormulaSales?.custom_formula, 
                                  name: t('xdataLocationDetails.custom'), 
                                  itemStyle:{ color: '#36333d' }
                              }
                          ]
                        }
                  ]
                }}/>
                <div style={{marginTop: '10px', display: 'flex'}}>
                  <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                  <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('xdataLocationDetails.last365Days')}</p>
                </div>
              </div>
              }
        </div>
        
      </div>
      <div ref={locationAnalyticsMachineStatref}>
        <CustomTable
            loading={ccLocationMachineStatLoading} 
            title ={t('xdataLocationDetails.machineStatistics')}
            thead={thead}
            tdata={machineStatData}
            maxRow={3}
            handleRowClick={handleRowClick}
            clickableColumn={-1}
            errorStatus={ccLocationMachineStatError}
            errorBlockBtnClick={errorBlockBtnClick}
            errorTopheight={"translateY(0%)"}
        />
        </div>

    </DashboardLayout>
    
  )
}